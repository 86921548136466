export const police = {
  personalData: {
    noAuthorized: `
        <h2>Согласие на обработку персональных данных</h2>
        <div>
          <p>
            Я подтверждаю, что являюсь
            <strong>совершеннолетним потребителем</strong> никотинсодержащей
            продукции, и добровольно, своей волей и в своем интересе, нажимая
            кнопку «Продолжить», я даю полное и безоговорочное согласие Обществу
            с ограниченной ответственностью «Дж.Т.И. Россия» (123112, Москва,
            проезд 1-й Красногвардейский, д. 15, этажи 28-32), далее –
            «Компания»,
          </p>
          <p>
            на осуществление обработки с использованием средств автоматизации
            и/или без использования таких средств, в том числе с передачей
            данных по сетям связи общего доступа, включая сбор, запись,
            систематизация, хранение, уточнение (обновление, изменение),
            извлечение, использование, передача (предоставление, доступ),
            трансграничная передача, блокирование, удаление, уничтожение
            полученных от меня или от третьих лиц моих персональных данных,
          </p>
          <p>
            <strong>с целью</strong> развития потребительского опыта при
            использовании продукции, взаимодействия с потребителями продукции,
            включая информирование пользователей продуктовых сайтов о
            деятельности и продукции Компании, проводимых активностях, что
            включает в себя регистрацию и проверку предоставленных конечными
            потребителями данных для доступа на сайты о продукции и к участию в
            программах, мероприятиях и активностях; составление статистической
            отчетности, аналитики данных об активностях потребителей для
            предоставления релевантной информации и повышения качества
            обслуживания, проведения опросов, исследований; организацию работы
            «Горячей линии», сервисов «Обратной связи»; взаимодействие с
            потребителями и направление информационных материалов о качественных
            характеристиках и потребительских свойствах продукции, программах,
            мероприятиях и активностях по любым каналам связи, включая, но не
            ограничиваясь, направление смс-сообщений, сообщений по электронной
            почте, передачу абонентам коротких текстовых сообщений, доступных
            для визуализации посредством использования программного обеспечения,
            установленного на их устройствах, в том числе всплывающих сообщений;
            обработку обращений потребителей; обеспечение работоспособности
            Сайта, в том числе с использованием информационных систем .
          </p>
          <p>
            Я подтверждаю и гарантирую, что предоставленные мной персональные
            данные соответствуют действительности и являются актуальными,
            полными и точными, я уведомлен и согласен, что проверка моих
            персональных данных производится для подтверждения принадлежности
            мне указанных мной данных и моего совершеннолетия и не влечет доступ
            к другим моим персональным данным или обработку каких-либо других
            персональных данных.
          </p>
          <p>
            Я соглашаюсь с тем, что Компания может передавать мои персональные
            данные третьим лицам для обработки и/или поручать обработку моих
            персональных данных третьим лицам в объеме, необходимом для
            достижения цели обработки, в том числе:
          </p>
          <ul>
            <li>
              <p>
                аффилированным лицам группы компаний JTI (в частности, головной
                компании JTI - JT International SA, (8, Рю Казем Раджави, 1202
                Женева, Швейцария),
              </p>
            </li>
            <li>
              <p>
                лицам, привлекаемым для оказания Компании услуг, в частности по
                подтверждению принадлежности потребителю указанных им
                персональных данных, проверке точности и достоверности
                персональных данных, услуг по обработке обращений потребителей,
                в том числе по Горячей линии, и информационному взаимодействию с
                потребителем, по поддержке и обслуживанию информационных систем
                Компании и группы компаний JTI, услуг по курьерскому
                обслуживанию, услуг, предоставляемых аудиторскими,
              </p>
            </li>
          </ul>
          <p>
            С полным перечнем третьих лиц, которым передаются персональные
            данные можно ознакомиться по ссылке
            <a href="/contractors" target="_blank">
              http://winston.ru/contractors
            </a>
          </p>
          <p>
            Настоящее согласие действует до момента прекращения взаимодействия
            со мной, в том числе по моей инициативе. Настоящее согласие может
            быть отозвано мной в любое время одним из следующих способов: 1)
            направив письмо с указанием фамилии, имени, отчества и адреса,
            указанных при регистрации, на адрес электронной почты
            contact@jdata.ru, 2) в разделе «Личный кабинет» на сайте, где
            производилась регистрация, 3) направив письменный запрос в адрес
            Компании, указанный выше.
          </p>
          <p>
            Я обязуюсь незамедлительно информировать, если предоставленные мной
            персональные данные утрачивают актуальность или изменяются.
          </p>
          <p>
            Я согласен с тем, что предоставленные мной персональные данные будут
            удалены в случае отзыва согласия в течение 30 (тридцати) дней с даты
            получения отзыва согласия.
          </p>
          <p>
            Я подтверждаю ознакомление с Политикой Конфиденциальности и принимаю
            ее условия.
          </p>
        </div>
    `,
    isAuthorized: `
        <h2>Согласие на обработку персональных данных</h2>
        <div>
          <p>
            Я подтверждаю, что являюсь
            <strong>совершеннолетним потребителем</strong> никотинсодержащей
            продукции, и добровольно, своей волей и в своем интересе, нажимая
            кнопку «Продолжить», я даю полное и безоговорочное согласие Обществу
            с ограниченной ответственностью «Дж.Т.И. Россия» (123112, Москва,
            проезд 1-й Красногвардейский, д. 15, этажи 28-32), далее –
            «Компания»,
          </p>
          <p>
            на осуществление обработки с использованием средств автоматизации
            и/или без использования таких средств, в том числе с передачей
            данных по сетям связи общего доступа, включая сбор, запись,
            систематизация, хранение, уточнение (обновление, изменение),
            извлечение, использование, передача (предоставление, доступ),
            трансграничная передача, блокирование, удаление, уничтожение
            полученных от меня или от третьих лиц моих персональных данных,
          </p>
          <p>
            <strong>с целью</strong> развития потребительского опыта при
            использовании продукции, взаимодействия с потребителями продукции,
            включая информирование пользователей продуктовых сайтов о
            деятельности и продукции Компании, проводимых активностях, что
            включает в себя регистрацию и проверку предоставленных конечными
            потребителями данных для доступа на сайты о продукции и к участию в
            программах, мероприятиях и активностях; составление статистической
            отчетности, аналитики данных об активностях потребителей для
            предоставления релевантной информации и повышения качества
            обслуживания, проведения опросов, исследований; организацию работы
            «Горячей линии», сервисов «Обратной связи»; взаимодействие с
            потребителями и направление информационных материалов о качественных
            характеристиках и потребительских свойствах продукции, программах,
            мероприятиях и активностях по любым каналам связи, включая, но не
            ограничиваясь, направление смс-сообщений, сообщений по электронной
            почте, передачу абонентам коротких текстовых сообщений, доступных
            для визуализации посредством использования программного обеспечения,
            установленного на их устройствах, в том числе всплывающих сообщений;
            обработку обращений потребителей; обеспечение работоспособности
            Сайта, в том числе с использованием информационных систем .
          </p>
          <p>
            Я подтверждаю и гарантирую, что предоставленные мной персональные
            данные соответствуют действительности и являются актуальными,
            полными и точными, я уведомлен и согласен, что проверка моих
            персональных данных производится для подтверждения принадлежности
            мне указанных мной данных и моего совершеннолетия и не влечет доступ
            к другим моим персональным данным или обработку каких-либо других
            персональных данных.
          </p>
          <p>
            Я соглашаюсь с тем, что Компания может передавать мои персональные
            данные третьим лицам для обработки и/или поручать обработку моих
            персональных данных третьим лицам в объеме, необходимом для
            достижения цели обработки, в том числе:
          </p>
          <ul>
            <li>
              <p>
                аффилированным лицам группы компаний JTI (в частности, головной
                компании JTI - JT International SA, (8, Рю Казем Раджави, 1202
                Женева, Швейцария),
              </p>
            </li>
            <li>
              <p>
                лицам, привлекаемым для оказания Компании услуг, в частности по
                подтверждению принадлежности потребителю указанных им
                персональных данных, проверке точности и достоверности
                персональных данных, услуг по обработке обращений потребителей,
                в том числе по Горячей линии, и информационному взаимодействию с
                потребителем, по поддержке и обслуживанию информационных систем
                Компании и группы компаний JTI, услуг по курьерскому
                обслуживанию, услуг, предоставляемых аудиторскими,
              </p>
            </li>
          </ul>
          <p>
            С полным перечнем третьих лиц, которым передаются персональные
            данные можно ознакомиться по ссылке
            <a href="/contractors" target="_blank">
              http://winston.ru/contractors
            </a>
          </p>
          <p>
            Настоящее согласие действует до момента прекращения взаимодействия
            со мной, в том числе по моей инициативе. Настоящее согласие может
            быть отозвано мной в любое время одним из следующих способов: 1)
            направив письмо с указанием фамилии, имени, отчества и адреса,
            указанных при регистрации, на адрес электронной почты
            contact@jdata.ru, 2) в разделе «Личный кабинет» на сайте, где
            производилась регистрация, 3) направив письменный запрос в адрес
            Компании, указанный выше.
          </p>
          <p>
            Я обязуюсь незамедлительно информировать, если предоставленные мной
            персональные данные утрачивают актуальность или изменяются.
          </p>
          <p>
            Я согласен с тем, что предоставленные мной персональные данные будут
            удалены в случае отзыва согласия в течение 30 (тридцати) дней с даты
            получения отзыва согласия.
          </p>
          <p>
            Я подтверждаю ознакомление с Политикой Конфиденциальности и принимаю
            ее условия.
          </p>
        </div>
    `,
  },
  termsConditions: {
    noAuthorized: `
            <h2>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ Об&nbsp;ИСПОЛЬЗОВАНИИ САЙТА</h2>
        <div>
          <time>Дата обновления: 14.11.2024</time>
        </div>
        <div>
          <h4>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h4>
          <p>
            1.1. В настоящем документе и вытекающих или связанным с ним
            отношениях Сторон применяются следующие термины и определения:
          </p>
          <p>
            <strong>Пользовательское соглашение (Соглашение)</strong> —
            настоящий документ со всеми дополнениями, изменениями и указанными в
            нем обязательными документами.
          </p>
          <p>
            <strong>Сайт</strong> – совокупность программных и аппаратных
            средств для ЭВМ, обеспечивающих публикацию информации и данных,
            объединенных общим целевым назначением, посредством технических
            средств, применяемых для связи между ЭВМ в сети Интернет. Сайт
            находится в сети Интернет по адресу: https://winston.ru/.
          </p>
          <p>
            <strong>Администратор/Владелец Сайта</strong> – Общество с
            ограниченной ответственностью «Дж.Т.И. Россия», адрес: 123112
            Москва, проезд 1-й Красногвардейский, д. 15, этажи 28-32, ОГРН
            1157746591815.
          </p>
          <p>
            <strong>Контент Сайта</strong> - контентом Сайта признаются все
            материалы, размещенные на Сайте, в том числе элементы дизайна,
            текст, графические изображения, иллюстрации, видео, скрипты, музыка,
            звуки и другие материалы и их подборки. Владелец Сайта является
            обладателем всех необходимых прав на использование контента Сайта,
            включая права на использование объектов интеллектуальной
            собственности.
          </p>
          <p>
            <strong>Пользователь Сайта/Пользователь</strong> – физическое
            дееспособное лицо старше 18 лет, потребитель табачной продукции,
            пользователь сети Интернет, который пользуется Сайтом, в том числе
            получает сервисы, контент Сайта, в соответствии с условиями
            настоящего Пользовательского соглашения.
          </p>
          <p>
            <strong>Учетная запись</strong> – индивидуальные имя Пользователя
            Сайта (логин) и пароль для доступа на Сайт.
          </p>
        </div>
        <div>
          <h4>2. ОБЩИЕ ПОЛОЖЕНИЯ</h4>
          <p>
            2.1. Настоящее Соглашение заключается между ООО «Дж.Т.И. Россия»
            (Владелец Сайта) и Пользователем Сайта и определяет условия
            использования Сайта.
          </p>
          <p>
            2.2. Предметом настоящего Пользовательского соглашения является
            предоставление Пользователю возможности пользоваться Сайтом, в том
            числе получать сервисы, контент Сайта. Все существующие на данный
            момент на Сайте сервисы, контент, а также любое развитие их и/или
            добавление новых являются объектами настоящего Соглашения.
          </p>
          <p>
            2.3. В соответствии со статьей 428 Гражданского кодекса РФ настоящее
            Пользовательское соглашение признается договором присоединения.
            Используя Сайт, в том числе, путем просмотра страниц и материалов
            Сайта, Пользователь подтверждает, что он ознакомлен и согласен с
            условиями настоящего Пользовательского соглашения. Использование
            Сайта любым способом и в любой форме в пределах его функциональных
            возможностей означает присоединение Пользователя к настоящему
            Соглашению и безоговорочное принятие его условий.
          </p>
          <p>
            2.4. В случае, если Пользователь не согласен с условиями настоящего
            Соглашения, или если Владельцем Сайта были внесены какие-либо
            изменения в Соглашение, с которыми Пользователь не согласен,
            Пользователь обязан прекратить использование Сайта, в противном
            случае продолжение использования Пользователем Сайта означает, что
            Пользователь согласен с условиями настоящего Соглашения.
          </p>
          <p>
            2.5. Использование сервисов, контента Сайта регулируется настоящим
            Пользовательским соглашением,  <button type="button" data-action="privacyPolicy">Политикой о конфиденциальности</button>.
            Соглашение может быть изменено Владельцем сайта без какого-либо
            специального уведомления, новая редакция Соглашения вступает в силу
            с момента ее размещения на Сайте, если иное не предусмотрено новой
            редакцией Соглашения.
          </p>
          <p>
            2.6. Сайт создан в целях предоставления Пользователям Сайта
            достоверной информации об основных потребительских свойствах и
            качественных характеристик табачной продукции Владельца Сайта в
            соответствии со статьей 10 Закона РФ от 07.02.1992 N 2300-1 "О
            защите прав потребителей».
          </p>
          <p>
            2.7. Содержащаяся на Сайте информация (в любом виде) не является
            рекламой табака, табачной продукции, табачных изделий,
            никотинсодержащей продукции, курительных принадлежностей и
            предназначена только для зарегистрированных Пользователей. Доступ на
            Сайт бесплатный. Приобретение табачной либо иной продукции для
            пользования Сайтом не требуется.
          </p>
          <p>2.8. Возможное содержание Сайта:</p>
          <ul>
            <li>
              <p>Главная страница</p>
            </li>
            <li>
              <p>Продукция/Портфель бренда</p>
            </li>
            <li>
              <p>Личный кабинет</p>
            </li>
            <li>
              <p>История бренда/Информация о табаке</p>
            </li>
            <li>
              <p>Помощь/Обратная связь</p>
            </li>
            <li>
              <p>Отзыв о продукте</p>
            </li>
          </ul>
          <p>
            2.9. Лицам, не достигшим совершеннолетия, пользование Сайтом
            запрещено.
          </p>
        </div>
        <div>
          <h4>3. УСЛОВИЯ ПОЛЬЗОВАНИЯ САЙТОМ</h4>
          <p>
            3.1. Использование Сайта допускается только после прохождения
            Пользователем регистрации на Сайте в соответствии с установленным
            порядком, за исключением использования сервиса «Помощь»/ «Обратная
            связь»/ «Напишите нам» в соответствии с п.3.8. настоящих Правил
          </p>
          <p>
            3.2. При регистрации Пользователю необходимо осуществить следующие
            действия:
          </p>
          <p class="popup-police__children">
            3.2.1. Подтвердить, что Пользователю исполнилось 18 лет, нажав на
            опцию «Мне есть 18 лет».
          </p>
          <p class="popup-police__children">
            3.2.2. Заполнить регистрационную анкету на Сайте, предоставив
            следующую информацию: фамилия имя, отчество (необходимо указать свои
            собственные достоверные фамилию, имя, отчество в соответствии с
            данными, указанными в документе, удостоверяющем личность), дату
            рождения, контактный номер телефона, пол.
          </p>
          <p class="popup-police__children">
            3.2.3. Дать согласие на обработку персональных данных и подтвердить
            ознакомление с Политикой о конфиденциальности, с настоящим
            Соглашением путем проставления соответствующей галочки.
          </p>
          <p class="popup-police__children">
            3.2.4. Дать согласие/не дать согласие на получение информационной
            рассылки от ООО «Дж.Т.И. Россия», включая передачу коротких
            текстовых сообщений, в том числе всплывающих сообщений, путем
            проставления/не проставления соответствующей галочки. При этом
            Владелец сайта имеет право отправлять Пользователям сообщения
            технического характера в соответствии с п.4.2.5. настоящего
            Соглашения.
          </p>
          <p class="popup-police__children">
            3.2.5. подтвердить номер телефона позвонив с указанного номера
            телефон на номер телефона, который будет сгенерирован на Сайте.
          </p>
          <p class="popup-police__children">
            3.2.6. загрузить на Сайт фотографию первой страницы паспорта.
            Указанные данные необходимы для целей подтверждения достоверности
            указанных в регистрационной анкете данных. Фотографическое
            изображение, содержащееся в паспорте, не используется.
          </p>
          <p class="popup-police__children">
            3.2.7. указать адрес электронной почты (email) и подтвердить его:
            необходимо проверить электронную почту и перейдите по ссылке из
            письма, которое будет оправлено на указанный email.
          </p>
          <p class="popup-police__children">
            3.2.8. указать адрес (населенный пункт); указать дополнительную
            информацию (какую марку сигарет Вы покупали и курили чаще всего в
            течение последних 4-х недель и какую марку сигарет Вы купите, если
            Вашей марки нет в продаже).
          </p>
          <p class="popup-police__children">
            3.2.9. придумать пароль для входа на Сайт.
          </p>
          <p>
            3.3. Каждому Пользователю при регистрации присваивается учетная
            запись – логин (телефон) и пароль; создается личный кабинет на
            Сайте. Владелец Сайта имеет право заблокировать доступ Пользователя
            к Сайту, если Пользователь зарегистрировал несколько учетных
            записей, либо Пользователь предоставил о себе неверную информацию,
            или есть подозрения Пользователя в мошенничестве.
          </p>
          <p>
            3.4. В случае, если Пользователь уже зарегистрирован на Сайте и
            имеет индивидуальные логин и пароль, необходимости в повторной
            регистрации нет. Пользователю для входа на Сайт необходимо пройти
            авторизацию, а именно ввести логин и пароль.
          </p>
          <p>
            3.5. Если Пользователем не доказано обратное, любые действия,
            совершенные с использованием его логина и пароля, считаются
            совершенными соответствующим Пользователем. Пользователь обязан
            обеспечивать конфиденциальность и безопасность логина и пароля, не
            передавать их третьим лицам. В случае несанкционированного доступа к
            логину и паролю и/или распространения логина и пароля Пользователь
            обязан незамедлительно сообщить об этом Владельцу Сайта.
          </p>
          <p>
            3.6. Если пароль был забыт/утерян Пользователь может его
            восстановить, воспользовавшись опцией «Восстановить»/«Забыли пароль»
            на Сайте, введя указанный при регистрации номер мобильного телефона.
          </p>
          <p>
            3.7. При изменении указанных при регистрации данных Пользователь
            обязуется уведомить об этом Владельца Сайта, используя сервис Сайта
            «Помощь»/ «Обратная связь»/«Обратиться в поддержку».
          </p>
          <p>
            3.8. Пользователь имеет право использовать без регистрации сервис
            Сайта «Помощь»/«Обратная связь»/ «Напишите нам» для связи с
            Владельцем Сайта. Пользователь заполняет поля формы для связи, в том
            числе касающиеся персональных данных (имя, адрес электронной почты),
            при необходимости прикрепляет файлы и дает своё согласие на
            обработку персональных данных и подтверждение ознакомления с
            Политикой о конфиденциальности путем проставления соответствующей
            галочки. Владелец Сайта связывается с Пользователем с помощью
            указанного адреса электронной почты или иным способом, указанным в
            сообщении Пользователем.
          </p>
          <p>
            3.9. На Сайте используются файлы cookies (куки) для аналитики и
            правильной работы Сайта. В случае отклонения Пользователем
            использования файлов cookies (куки) это может повлиять на работу
            некоторых функций или разделов Сайта.
          </p>
        </div>
        <div>
          <h4>4. ПРАВА И ОБЯЗАННОСТИ ВЛАДЕЛЬЦА САЙТА</h4>
          <p>4.1. Владелец Сайта обязуется:</p>
          <p class="popup-police__children">
            4.1.1 Предоставлять Пользователям доступ на Сайт и возможность
            пользоваться Сайтом, в том числе получать сервисы, контент в
            соответствии с условиями настоящего Пользовательского соглашения, а
            также возможность участвовать в проводимых Владельцем Сайта
            программах, мероприятиях и иных активностях доступных на Сайте.
          </p>
          <p class="popup-police__children">
            4.1.2. Не разглашать третьим лицам персональные данные Пользователей
            и обеспечивать их безопасность и конфиденциальность в соответствии с
            условиями Политики о конфиденциальности.
          </p>
          <p class="popup-police__children">
            4.1.3. Обеспечивать круглосуточную доступность сервера, на котором
            расположен Сайт, за исключением времени проведения профилактических
            работа, а также аварий или сбоев в программно-аппаратных комплексах
            третьих лиц, сотрудничающих с Владельцем Сайта, или действий
            (бездействия) третьих лиц, направленных на приостановку или
            прекращение функционирования Сайта.
          </p>
          <p>4.2. Владелец Сайта имеет право:</p>
          <p class="popup-police__children">
            4.2.1. В одностороннем порядке заблокировать доступ Пользователя к
            Сайту в случае нарушения Пользователем условий настоящего
            Соглашения.
          </p>
          <p class="popup-police__children">
            4.2.2. Формировать базу данных пользователей Сайта и осуществлять
            обработку персональных данных Пользователей в соответствии с
            условиями согласия Пользователя на обработку персональных данных и
            Политикой о конфиденциальности.
          </p>
          <p class="popup-police__children">
            4.2.3. Составлять статистическую отчетность и аналитику данных об
            активностях Пользователей на Сайте для повышения качества
            обслуживания, проведения опросов, исследований; распоряжаться
            статистической информацией, связанной с функционированием Сайта, а
            также использовать информацию, полученную с помощью cookie и сервиса
            веб-аналитики Яндекс.Метрика, в целях ведения статистики посещений
            для оценки и анализа работы Сайта и улучшения сервисов.
          </p>
          <p class="popup-police__children">
            4.2.4. Осуществлять взаимодействие с Пользователями (при наличии их
            согласия) путем направления информационных материалов о качественных
            характеристиках и потребительских свойствах продукции, программах,
            мероприятиях и активностях на Сайте по любым каналам связи, включая,
            но не ограничиваясь, направление смс-сообщений, сообщений по
            электронной почте, передачу абонентам коротких текстовых сообщений,
            доступных для визуализации посредством использования программного
            обеспечения, установленного на их устройствах, в том числе
            всплывающих сообщений; осуществлять обработку обращений
            потребителей.
          </p>
          <p class="popup-police__children">
            4.2.5. Отправлять Пользователям сообщения, связанные с
            регистрацией/авторизацией Пользователей на Сайте и изменением
            пароля, данных Пользователей на Сайте. Данный вид сообщений не
            является информационным, содержит верификационные данные и не
            требует получения согласий Пользователей на их отправление.
          </p>
        </div>
        <div>
          <h4>5. ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЕЙ</h4>
          <p>5.1. Пользователь обязуется:</p>
          <p class="popup-police__children">
            5.1.1. Полностью ознакомиться с условиями настоящего Соглашения до
            момента использования Сайта.
          </p>
          <p class="popup-police__children">
            5.1.2. Соблюдать все условия настоящего Соглашения. Пользователь
            обязан использовать Сайт исключительно в законных целях и таким
            образом, чтобы не нарушать права других Пользователей, не
            ограничивать и не препятствовать доступу других Пользователей на
            Сайт и пользованию им.
          </p>
          <p class="popup-police__children">
            5.1.3. Не передавать информацию об учетной записи на Сайте (логине и
            пароле) третьим лицам.
          </p>
          <p class="popup-police__children">
            5.1.4. Не использовать персональные данные третьих лиц для
            использования Сайта/для регистрации на Сайте; не использовать Сайт
            от имени или вместо другого лица; не регистрировать юридическое
            лицо/индивидуального предпринимателя на Сайте.
          </p>
          <p class="popup-police__children">
            5.1.5. Не загружать, не распространять на Сайте любую информацию,
            которая:
          </p>
          <ul class="popup-police__children">
            <li>
              <p>
                содержит угрозы, дискредитирует, оскорбляет, порочит честь и
                достоинство или деловую репутацию или нарушает
                неприкосновенность частной жизни других Пользователей или
                третьих лиц.
              </p>
            </li>
            <li>
              <p>нарушает права третьих лиц, включая несовершеннолетних.</p>
            </li>
            <li>
              <p>
                а также нарушает иные права и интересы граждан и юридических лиц
                или требования законодательства РФ.
              </p>
            </li>
          </ul>
          <p class="popup-police__children">
            5.1.6. Не использовать программное обеспечение и не осуществлять
            действия, направленные на нарушение нормального функционирования
            Сайта и его сервисов или личных кабинетов Пользователей, не
            загружать, не хранить, не публиковать, не распространять и не
            предоставлять доступ или иным образом использовать вирусы, трояны и
            другие вредоносные программы; не использовать без специального на то
            разрешения Владельца Сайта автоматизированные скрипты (программы)
            для сбора информации на Сайте и (или) взаимодействия с Сайтом и его
            сервисами.
          </p>
          <p class="popup-police__children">
            5.1.7. Не использовать Сайт для рекламы или предложения коммерческих
            услуг.
          </p>
          <p>
            5.2. Владелец Сайта обладает всеми необходимыми правами на
            материалы, размещенные на Сайте, включая права на использование
            объектов интеллектуальной собственности. Пользователь Сайта не имеет
            право скачивать, копировать, рассылать/передавать третьим лицам
            размещенные на Сайте материалы, без разрешения Владельца Сайта или
            соответствующего правообладателя.
          </p>
          <p>5.3. Пользователь имеет право:</p>
          <p class="popup-police__children">
            5.3.2. Круглосуточно получать доступ к серверу, на котором
            расположен Сайт, за исключением времени проведения профилактических
            работ, а также аварий или сбоев в программно-аппаратных комплексах
            третьих лиц, сотрудничающих с Владельцем Сайта, или действий
            (бездействия) третьих лиц, направленных на нарушения информационной
            безопасности или нормального функционирования Сайта.
          </p>
          <p class="popup-police__children">
            5.3.3. Самостоятельно редактировать/менять ранее размещенную о себе
            информацию на Сайте, а именно пароль, контактный телефон, адрес
            электронной почты. Для редактирования иной размещенной информации о
            Пользователе необходимо обратиться к Владельцу Сайта, если иное не
            указано на Сайте.
          </p>
          <p class="popup-police__children">
            5.3.4. Управлять рассылкой информационных сообщений путем
            проставления соответствующих галочек в разделе «Управление
            рассылкой»/ «Подписка на рассылку» в личном кабинете. При этом
            проставив галочки в выбранных опциях Пользователь дает согласие на
            получение информационной рассылки от Владельца Сайта на выбранных
            условиях. При отсутствии галочек в предложенных опциях
            информационная рассылка осуществляться не будет.
          </p>
          <p>
            5.4. Пользователь согласен с тем, что, осуществляя доступ к Сайту и
            пользуясь его сервисами, контентом, он:
          </p>
          <p class="popup-police__children">
            5.4.1. Выражает свое безоговорочное согласие со всеми условиями
            настоящего Соглашения и обязуется их соблюдать или прекратить
            использование Сайта.
          </p>
          <p class="popup-police__children">
            5.4.2. Самостоятельно несет ответственность перед третьими лицами за
            свои действия, связанные с использованием Сайта, в том числе если
            такие действия приведут к нарушению прав и законных интересов
            третьих лиц, а также за соблюдение законодательства РФ при
            использовании Сайта.
          </p>
          <p>
            5.5. В целях реализации настоящего Соглашения Пользователи
            предоставляют Владельцу Сайта согласие на обработку их персональных
            данных в соответствии с Политикой о конфиденциальности. При этом
            Пользователь имеет право в любое время отозвать выданное им согласие
            одним из следующих способов:
          </p>
          <ol class="popup-police__children">
            <li>
              <p>
                направив письмо с указанием фамилии, имени, отчества и адреса,
                указанных при регистрации на Сайте, на адрес электронной почты
                contact@jdata.ru,
              </p>
            </li>
            <li>
              <p>
                в разделе «Личный кабинет» на Сайте, где производилась
                регистрация,
              </p>
            </li>
            <li>
              <p>
                направив письменный запрос в адрес Владельца Сайта - Обществу с
                ограниченной ответственностью «Дж.Т.И. Россия» по адресу:
                123112, Москва, проезд 1-й Красногвардейский, д. 15, этажи
                28-32).
              </p>
            </li>
          </ol>
          <p>
            Представленные Пользователем персональные данные будут удалены в
            случае их отзыва в течение 30 (тридцати) дней с даты получения
            Владельцем Сайта отзыва согласия.
          </p>
        </div>
        <div>
          <h4>6. ОТЗЫВЫ ПОЛЬЗОВАТЕЛЕЙ</h4>
          <p>
            6.1. Владелец Сайта может собирать на Сайте отзывы Пользователей о
            продукции, размещенной на Сайте. В случае наличия на Сайте сервиса
            «Оставить отзыв» направить Владельцу Сайта отзыв о продукции,
            размещенной на Сайте, могут все зарегистрированные Пользователи
            Сайта. Отзывы проходят модерацию и предназначены для Владельца
            Сайта. При этом Владелец сайта имеет право опубликовывать на Сайте
            отзывы, прошедшие модерацию. Отзывы, которые противоречат условиям
            настоящего Соглашения, не могут быть опубликованы на Сайте и
            удаляются.
          </p>
          <p>
            6.2. Работникам и представителям Владельца Сайта, членам их семей, а
            также работникам и представителям любых других лиц, имеющих
            непосредственное отношение к производству продукции, информация о
            которой размещена на Сайте, запрещается направлять отзывы.
          </p>
          <p>6.3. Отзывы не должны содержать изображения:</p>
          <ul class="popup-police__children">
            <li>
              <p>беременных женщин,</p>
            </li>
            <li>
              <p>несовершеннолетних,</p>
            </li>
            <li>
              <p>процесса курения и распития спиртных напитков,</p>
            </li>
            <li>
              <p>элементов жестокости или противозаконных действий,</p>
            </li>
            <li>
              <p>известных или популярных личностей,</p>
            </li>
            <li>
              <p>игрушек и других предметов, связанных с детьми,</p>
            </li>
            <li>
              <p>персонажей сказок или мультфильмов,</p>
            </li>
            <li>
              <p>сцен эротического содержания,</p>
            </li>
            <li>
              <p>спортивной тематики,</p>
            </li>
            <li>
              <p>
                сцен, оскорбляющих нравственность, пропагандирующих ненависть
                и/или дискриминацию людей по расовому, этническому, половому,
                религиозному, социальному признака,
              </p>
            </li>
          </ul>
          <p>6.4. Отзывы не должны:</p>
          <ul class="popup-police__children">
            <li>
              <p>относиться к категории рекламы,</p>
            </li>
            <li>
              <p>содержать ссылки на какие-либо интернет-ресурсы,</p>
            </li>
            <li>
              <p>нарушать права и интересы третьих лиц,</p>
            </li>
            <li>
              <p>
                содержать нецензурную лексику и оскорбительные высказывания, в
                том числе в адрес конкретных лиц или организаций;
                пропагандировать ненависть и/или дискриминацию людей по
                расовому, этническому, половому, религиозному, социальному
                признакам,
              </p>
            </li>
            <li>
              <p>
                являться незаконными, вредоносными, угрожающими,
                клеветническими, безнравственными,
              </p>
            </li>
            <li>
              <p>
                ограничивать или запрещать любому другому Пользователю
                использование Сайта,
              </p>
            </li>
            <li>
              <p>
                поощрять незаконное поведение или содержать вирус или другой
                вредный компонент, ложную или вводящую в заблуждение,
                недостоверную информацию.
              </p>
            </li>
          </ul>
          <p>
            6.5. Владелец Сайта имеет право связываться с Пользователем в связи
            с отзывом с помощью указанных при регистрации адреса электронной
            почты или телефона.
          </p>
          <p>
            6.6. Оставляя отзыв на Сайте Пользователь подтверждает, что
            ознакомлен и согласен с условиями настоящего Соглашения, а также
            согласен на модерацию и публикацию отзыва на Сайте.
          </p>
        </div>
        <div>
          <h4>7. ОТВЕТСТВЕННОСТЬ СТОРОН</h4>
          <p>
            7.1. Владелец Сайта прилагает все возможные усилия для обеспечения
            нормальной работоспособности Сайта, однако не несет ответственности
            за неисполнение или ненадлежащее исполнение обязательств по
            Соглашению, а также возможные убытки, возникшие в том числе, но не
            ограничиваясь, в результате:
          </p>
          <ul class="popup-police__children">
            <li>
              <p>
                неправомерных действий Пользователей, третьих лиц, направленных
                на нарушения информационной безопасности или нормального
                функционирования Сайта;
              </p>
            </li>
            <li>
              <p>
                сбоев в работе Сайта, вызванных ошибками в коде, компьютерными
                вирусами и иными посторонними фрагментами кода в программном
                обеспечении Сайта;
              </p>
            </li>
            <li>
              <p>
                отсутствия (невозможности установления, прекращения и пр.)
                интернет-соединений между сервером Пользователя и сервером
                Сайта;
              </p>
            </li>
            <li>
              <p>
                установления государственного регулирования (или регулирования
                иными организациями) хозяйственной деятельности коммерческих
                организаций в сети Интернет и/или установления указанными
                субъектами разовых ограничений, затрудняющих или делающих
                невозможным исполнение Соглашения;
              </p>
            </li>
            <li>
              <p>
                других случаев, связанных с действиями (бездействием)
                Пользователей и/или других субъектов, направленными на ухудшение
                общей ситуации с использованием сети Интернет и/или
                компьютерного оборудования, существовавшей на момент заключения
                Соглашения, а также любых других действий, направленных на Сайт;
              </p>
            </li>
            <li>
              <p>
                выполнения профилактических работ или приостановки Сайта,
                указанных в пунктах 7.4 и 7.5 настоящего Соглашения.
              </p>
            </li>
          </ul>
          <p>
            7.2. Владелец Сайта имеет право производить профилактические работы
            в программно-аппаратном комплексе Сайта с временным приостановлением
            работы Сайта по возможности в ночное время и максимально сокращая
            время неработоспособности Сайта.
          </p>
          <p>
            7.3. В случае наступления форс-мажорных обстоятельств, а также
            аварий или сбоев в программно-аппаратных комплексах третьих лиц,
            сотрудничающих с Владельцем Сайта, или действий (бездействия)
            третьих лиц, направленных на приостановку или прекращение
            функционирования Сайта, возможна приостановка работы Сайта.
          </p>
          <p>
            7.4. Владелец Сайта не несет ответственности за нарушение
            Пользователем настоящего Соглашения и оставляет за собой право по
            своему собственному усмотрению, а также при получении информации от
            других Пользователей либо третьих лиц о нарушении Пользователем
            настоящего Соглашения изменять (модерировать) или удалять любую
            информацию, предоставленную Пользователем, нарушающую запреты,
            установленные настоящим Соглашением, (включая личные сообщения),
            приостанавливать, ограничивать или прекращать доступ Пользователя ко
            всем или к любому из разделов или сервисов Сайта в любое время по
            любой причине или без объяснения причин, с предварительным
            уведомлением или без такового, не отвечая за любой вред, который
            может быть причинен таким действием.
          </p>
          <p>
            7.5. Владелец Сайта не несет ответственности за любой ущерб
            компьютеру Пользователя или иного лица, мобильным устройствам,
            любому другому оборудованию или программному обеспечению, вызванный
            или связанный со скачиванием материалов с Сайта или по ссылкам,
            размещенным на Сайте.
          </p>
          <p>
            7.6. Ни одна из Сторон не несет ответственности за полное или
            частичное неисполнение любой из своих обязанностей, если
            неисполнение является следствием таких обстоятельств, как
            наводнение, пожар, землетрясение, другие стихийные бедствия, война
            или военные действия и другие обстоятельства непреодолимой силы,
            возникшие после заключения Соглашения и не зависящие от воли Сторон.
          </p>
          <p>
            7.7. Владелец Сайта не несет ответственности за убытки, причиненные
            Пользователю в результате сообщения другим Пользователем
            недостоверной информации, а также причиненные действиями
            (бездействием) другого Пользователя или третьего лица.
          </p>
          <p>
            7.8. Владелец сайта прикладывает все усилия для обеспечения
            безопасности Сайта, его защиты от вирусов/вредоносных программ и не
            несет ответственность в случаях наличия ошибок и/или компьютерных
            вирусов или посторонних фрагментов кода вследствие действий третьих
            лиц, направленных на нарушение информационной безопасности или
            нормального функционирования Сайта.
          </p>
        </div>
        <div>
          <h4>8. ПРОЧИЕ УСЛОВИЯ</h4>
          <p>
            8.1. В случае возникновения споров между Пользователем и Владельцем
            Сайта по вопросам, связанным с исполнением Соглашения, Стороны
            примут все меры к разрешению их путем переговоров. Претензионный
            порядок разрешения споров обязателен. Претензии направляются
            Владельцу Сайта в письменном виде посредством отправки по почте
            заказным письмом с уведомлением о вручении.
          </p>
          <p>
            8.2. При недостижении согласия между Сторонами путем переговоров
            спор, вытекающий из настоящего Соглашения, подлежит рассмотрению в
            суде общей юрисдикции по месту нахождения Владельца Сайта.
          </p>
          <p>
            8.3. Настоящее Соглашение регулируется и толкуется в соответствии с
            законодательством Российской Федерации. Вопросы, не урегулированные
            настоящим Соглашением, подлежат разрешению в соответствии с
            законодательством Российской Федерации.
          </p>
          <p>
            8.4. Положения настоящего Соглашения устанавливаются, изменяются и
            отменяются Владельцем Сайта в одностороннем порядке без
            предварительного уведомления Пользователя. Если Пользователь не
            согласен с условиями настоящего Соглашения, то он должен немедленно
            прекратить пользоваться сервисами и контентом Сайта, в противном
            случае продолжение использования Пользователем Сайта означает, что
            Пользователь согласен с условиями настоящего Соглашения.
          </p>
        </div>
    `,
    isAuthorized: `
            <h2>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ Об&nbsp;ИСПОЛЬЗОВАНИИ САЙТА</h2>
        <div>
          <time>Дата обновления: 14.11.2024</time>
        </div>
        <div>
          <h4>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h4>
          <p>
            1.1. В настоящем документе и вытекающих или связанным с ним
            отношениях Сторон применяются следующие термины и определения:
          </p>
          <p>
            <strong>Пользовательское соглашение (Соглашение)</strong> —
            настоящий документ со всеми дополнениями, изменениями и указанными в
            нем обязательными документами.
          </p>
          <p>
            <strong>Сайт</strong> – совокупность программных и аппаратных
            средств для ЭВМ, обеспечивающих публикацию информации и данных,
            объединенных общим целевым назначением, посредством технических
            средств, применяемых для связи между ЭВМ в сети Интернет. Сайт
            находится в сети Интернет по адресу: https://winston.ru/.
          </p>
          <p>
            <strong>Администратор/Владелец Сайта</strong> – Общество с
            ограниченной ответственностью «Дж.Т.И. Россия», адрес: 123112
            Москва, проезд 1-й Красногвардейский, д. 15, этажи 28-32, ОГРН
            1157746591815.
          </p>
          <p>
            <strong>Контент Сайта</strong> - контентом Сайта признаются все
            материалы, размещенные на Сайте, в том числе элементы дизайна,
            текст, графические изображения, иллюстрации, видео, скрипты, музыка,
            звуки и другие материалы и их подборки. Владелец Сайта является
            обладателем всех необходимых прав на использование контента Сайта,
            включая права на использование объектов интеллектуальной
            собственности.
          </p>
          <p>
            <strong>Пользователь Сайта/Пользователь</strong> – физическое
            дееспособное лицо старше 18 лет, потребитель табачной продукции,
            пользователь сети Интернет, который пользуется Сайтом, в том числе
            получает сервисы, контент Сайта, в соответствии с условиями
            настоящего Пользовательского соглашения.
          </p>
          <p>
            <strong>Учетная запись</strong> – индивидуальные имя Пользователя
            Сайта (логин) и пароль для доступа на Сайт.
          </p>
        </div>
        <div>
          <h4>2. ОБЩИЕ ПОЛОЖЕНИЯ</h4>
          <p>
            2.1. Настоящее Соглашение заключается между ООО «Дж.Т.И. Россия»
            (Владелец Сайта) и Пользователем Сайта и определяет условия
            использования Сайта.
          </p>
          <p>
            2.2. Предметом настоящего Пользовательского соглашения является
            предоставление Пользователю возможности пользоваться Сайтом, в том
            числе получать сервисы, контент Сайта. Все существующие на данный
            момент на Сайте сервисы, контент, а также любое развитие их и/или
            добавление новых являются объектами настоящего Соглашения.
          </p>
          <p>
            2.3. В соответствии со статьей 428 Гражданского кодекса РФ настоящее
            Пользовательское соглашение признается договором присоединения.
            Используя Сайт, в том числе, путем просмотра страниц и материалов
            Сайта, Пользователь подтверждает, что он ознакомлен и согласен с
            условиями настоящего Пользовательского соглашения. Использование
            Сайта любым способом и в любой форме в пределах его функциональных
            возможностей означает присоединение Пользователя к настоящему
            Соглашению и безоговорочное принятие его условий.
          </p>
          <p>
            2.4. В случае, если Пользователь не согласен с условиями настоящего
            Соглашения, или если Владельцем Сайта были внесены какие-либо
            изменения в Соглашение, с которыми Пользователь не согласен,
            Пользователь обязан прекратить использование Сайта, в противном
            случае продолжение использования Пользователем Сайта означает, что
            Пользователь согласен с условиями настоящего Соглашения.
          </p>
          <p>
            2.5. Использование сервисов, контента Сайта регулируется настоящим
            Пользовательским соглашением, <button type="button" data-action="privacyPolicy">Политикой о конфиденциальности</button>.
            Соглашение может быть изменено Владельцем сайта без какого-либо
            специального уведомления, новая редакция Соглашения вступает в силу
            с момента ее размещения на Сайте, если иное не предусмотрено новой
            редакцией Соглашения.
          </p>
          <p>
            2.6. Сайт создан в целях предоставления Пользователям Сайта
            достоверной информации об основных потребительских свойствах и
            качественных характеристик табачной продукции Владельца Сайта в
            соответствии со статьей 10 Закона РФ от 07.02.1992 N 2300-1 "О
            защите прав потребителей».
          </p>
          <p>
            2.7. Содержащаяся на Сайте информация (в любом виде) не является
            рекламой табака, табачной продукции, табачных изделий,
            никотинсодержащей продукции, курительных принадлежностей и
            предназначена только для зарегистрированных Пользователей. Доступ на
            Сайт бесплатный. Приобретение табачной либо иной продукции для
            пользования Сайтом не требуется.
          </p>
          <p>2.8. Возможное содержание Сайта:</p>
          <ul>
            <li>
              <p>Главная страница</p>
            </li>
            <li>
              <p>Продукция/Портфель бренда</p>
            </li>
            <li>
              <p>Личный кабинет</p>
            </li>
            <li>
              <p>История бренда/Информация о табаке</p>
            </li>
            <li>
              <p>Помощь/Обратная связь</p>
            </li>
            <li>
              <p>Отзыв о продукте</p>
            </li>
          </ul>
          <p>
            2.9. Лицам, не достигшим совершеннолетия, пользование Сайтом
            запрещено.
          </p>
        </div>
        <div>
          <h4>3. УСЛОВИЯ ПОЛЬЗОВАНИЯ САЙТОМ</h4>
          <p>
            3.1. Использование Сайта допускается только после прохождения
            Пользователем регистрации на Сайте в соответствии с установленным
            порядком, за исключением использования сервиса «Помощь»/ «Обратная
            связь»/ «Напишите нам» в соответствии с п.3.8. настоящих Правил
          </p>
          <p>
            3.2. При регистрации Пользователю необходимо осуществить следующие
            действия:
          </p>
          <p class="popup-police__children">
            3.2.1. Подтвердить, что Пользователю исполнилось 18 лет, нажав на
            опцию «Мне есть 18 лет».
          </p>
          <p class="popup-police__children">
            3.2.2. Заполнить регистрационную анкету на Сайте, предоставив
            следующую информацию: фамилия имя, отчество (необходимо указать свои
            собственные достоверные фамилию, имя, отчество в соответствии с
            данными, указанными в документе, удостоверяющем личность), дату
            рождения, контактный номер телефона, пол.
          </p>
          <p class="popup-police__children">
            3.2.3. Дать согласие на обработку персональных данных и подтвердить
            ознакомление с Политикой о конфиденциальности, с настоящим
            Соглашением путем проставления соответствующей галочки.
          </p>
          <p class="popup-police__children">
            3.2.4. Дать согласие/не дать согласие на получение информационной
            рассылки от ООО «Дж.Т.И. Россия», включая передачу коротких
            текстовых сообщений, в том числе всплывающих сообщений, путем
            проставления/не проставления соответствующей галочки. При этом
            Владелец сайта имеет право отправлять Пользователям сообщения
            технического характера в соответствии с п.4.2.5. настоящего
            Соглашения.
          </p>
          <p class="popup-police__children">
            3.2.5. подтвердить номер телефона позвонив с указанного номера
            телефон на номер телефона, который будет сгенерирован на Сайте.
          </p>
          <p class="popup-police__children">
            3.2.6. загрузить на Сайт фотографию первой страницы паспорта.
            Указанные данные необходимы для целей подтверждения достоверности
            указанных в регистрационной анкете данных. Фотографическое
            изображение, содержащееся в паспорте, не используется.
          </p>
          <p class="popup-police__children">
            3.2.7. указать адрес электронной почты (email) и подтвердить его:
            необходимо проверить электронную почту и перейдите по ссылке из
            письма, которое будет оправлено на указанный email.
          </p>
          <p class="popup-police__children">
            3.2.8. указать адрес (населенный пункт); указать дополнительную
            информацию (какую марку сигарет Вы покупали и курили чаще всего в
            течение последних 4-х недель и какую марку сигарет Вы купите, если
            Вашей марки нет в продаже).
          </p>
          <p class="popup-police__children">
            3.2.9. придумать пароль для входа на Сайт.
          </p>
          <p>
            3.3. Каждому Пользователю при регистрации присваивается учетная
            запись – логин (телефон) и пароль; создается личный кабинет на
            Сайте. Владелец Сайта имеет право заблокировать доступ Пользователя
            к Сайту, если Пользователь зарегистрировал несколько учетных
            записей, либо Пользователь предоставил о себе неверную информацию,
            или есть подозрения Пользователя в мошенничестве.
          </p>
          <p>
            3.4. В случае, если Пользователь уже зарегистрирован на Сайте и
            имеет индивидуальные логин и пароль, необходимости в повторной
            регистрации нет. Пользователю для входа на Сайт необходимо пройти
            авторизацию, а именно ввести логин и пароль.
          </p>
          <p>
            3.5. Если Пользователем не доказано обратное, любые действия,
            совершенные с использованием его логина и пароля, считаются
            совершенными соответствующим Пользователем. Пользователь обязан
            обеспечивать конфиденциальность и безопасность логина и пароля, не
            передавать их третьим лицам. В случае несанкционированного доступа к
            логину и паролю и/или распространения логина и пароля Пользователь
            обязан незамедлительно сообщить об этом Владельцу Сайта.
          </p>
          <p>
            3.6. Если пароль был забыт/утерян Пользователь может его
            восстановить, воспользовавшись опцией «Восстановить»/«Забыли пароль»
            на Сайте, введя указанный при регистрации номер мобильного телефона.
          </p>
          <p>
            3.7. При изменении указанных при регистрации данных Пользователь
            обязуется уведомить об этом Владельца Сайта, используя сервис Сайта
            «Помощь»/ «Обратная связь»/«Обратиться в поддержку».
          </p>
          <p>
            3.8. Пользователь имеет право использовать без регистрации сервис
            Сайта «Помощь»/«Обратная связь»/ «Напишите нам» для связи с
            Владельцем Сайта. Пользователь заполняет поля формы для связи, в том
            числе касающиеся персональных данных (имя, адрес электронной почты),
            при необходимости прикрепляет файлы и дает своё согласие на
            обработку персональных данных и подтверждение ознакомления с
            Политикой о конфиденциальности путем проставления соответствующей
            галочки. Владелец Сайта связывается с Пользователем с помощью
            указанного адреса электронной почты или иным способом, указанным в
            сообщении Пользователем.
          </p>
          <p>
            3.9. На Сайте используются файлы cookies (куки) для аналитики и
            правильной работы Сайта. В случае отклонения Пользователем
            использования файлов cookies (куки) это может повлиять на работу
            некоторых функций или разделов Сайта.
          </p>
        </div>
        <div>
          <h4>4. ПРАВА И ОБЯЗАННОСТИ ВЛАДЕЛЬЦА САЙТА</h4>
          <p>4.1. Владелец Сайта обязуется:</p>
          <p class="popup-police__children">
            4.1.1 Предоставлять Пользователям доступ на Сайт и возможность
            пользоваться Сайтом, в том числе получать сервисы, контент в
            соответствии с условиями настоящего Пользовательского соглашения, а
            также возможность участвовать в проводимых Владельцем Сайта
            программах, мероприятиях и иных активностях доступных на Сайте.
          </p>
          <p class="popup-police__children">
            4.1.2. Не разглашать третьим лицам персональные данные Пользователей
            и обеспечивать их безопасность и конфиденциальность в соответствии с
            условиями Политики о конфиденциальности.
          </p>
          <p class="popup-police__children">
            4.1.3. Обеспечивать круглосуточную доступность сервера, на котором
            расположен Сайт, за исключением времени проведения профилактических
            работа, а также аварий или сбоев в программно-аппаратных комплексах
            третьих лиц, сотрудничающих с Владельцем Сайта, или действий
            (бездействия) третьих лиц, направленных на приостановку или
            прекращение функционирования Сайта.
          </p>
          <p>4.2. Владелец Сайта имеет право:</p>
          <p class="popup-police__children">
            4.2.1. В одностороннем порядке заблокировать доступ Пользователя к
            Сайту в случае нарушения Пользователем условий настоящего
            Соглашения.
          </p>
          <p class="popup-police__children">
            4.2.2. Формировать базу данных пользователей Сайта и осуществлять
            обработку персональных данных Пользователей в соответствии с
            условиями согласия Пользователя на обработку персональных данных и
            Политикой о конфиденциальности.
          </p>
          <p class="popup-police__children">
            4.2.3. Составлять статистическую отчетность и аналитику данных об
            активностях Пользователей на Сайте для повышения качества
            обслуживания, проведения опросов, исследований; распоряжаться
            статистической информацией, связанной с функционированием Сайта, а
            также использовать информацию, полученную с помощью cookie и сервиса
            веб-аналитики Яндекс.Метрика, в целях ведения статистики посещений
            для оценки и анализа работы Сайта и улучшения сервисов.
          </p>
          <p class="popup-police__children">
            4.2.4. Осуществлять взаимодействие с Пользователями (при наличии их
            согласия) путем направления информационных материалов о качественных
            характеристиках и потребительских свойствах продукции, программах,
            мероприятиях и активностях на Сайте по любым каналам связи, включая,
            но не ограничиваясь, направление смс-сообщений, сообщений по
            электронной почте, передачу абонентам коротких текстовых сообщений,
            доступных для визуализации посредством использования программного
            обеспечения, установленного на их устройствах, в том числе
            всплывающих сообщений; осуществлять обработку обращений
            потребителей.
          </p>
          <p class="popup-police__children">
            4.2.5. Отправлять Пользователям сообщения, связанные с
            регистрацией/авторизацией Пользователей на Сайте и изменением
            пароля, данных Пользователей на Сайте. Данный вид сообщений не
            является информационным, содержит верификационные данные и не
            требует получения согласий Пользователей на их отправление.
          </p>
        </div>
        <div>
          <h4>5. ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЕЙ</h4>
          <p>5.1. Пользователь обязуется:</p>
          <p class="popup-police__children">
            5.1.1. Полностью ознакомиться с условиями настоящего Соглашения до
            момента использования Сайта.
          </p>
          <p class="popup-police__children">
            5.1.2. Соблюдать все условия настоящего Соглашения. Пользователь
            обязан использовать Сайт исключительно в законных целях и таким
            образом, чтобы не нарушать права других Пользователей, не
            ограничивать и не препятствовать доступу других Пользователей на
            Сайт и пользованию им.
          </p>
          <p class="popup-police__children">
            5.1.3. Не передавать информацию об учетной записи на Сайте (логине и
            пароле) третьим лицам.
          </p>
          <p class="popup-police__children">
            5.1.4. Не использовать персональные данные третьих лиц для
            использования Сайта/для регистрации на Сайте; не использовать Сайт
            от имени или вместо другого лица; не регистрировать юридическое
            лицо/индивидуального предпринимателя на Сайте.
          </p>
          <p class="popup-police__children">
            5.1.5. Не загружать, не распространять на Сайте любую информацию,
            которая:
          </p>
          <ul class="popup-police__children">
            <li>
              <p>
                содержит угрозы, дискредитирует, оскорбляет, порочит честь и
                достоинство или деловую репутацию или нарушает
                неприкосновенность частной жизни других Пользователей или
                третьих лиц.
              </p>
            </li>
            <li>
              <p>нарушает права третьих лиц, включая несовершеннолетних.</p>
            </li>
            <li>
              <p>
                а также нарушает иные права и интересы граждан и юридических лиц
                или требования законодательства РФ.
              </p>
            </li>
          </ul>
          <p class="popup-police__children">
            5.1.6. Не использовать программное обеспечение и не осуществлять
            действия, направленные на нарушение нормального функционирования
            Сайта и его сервисов или личных кабинетов Пользователей, не
            загружать, не хранить, не публиковать, не распространять и не
            предоставлять доступ или иным образом использовать вирусы, трояны и
            другие вредоносные программы; не использовать без специального на то
            разрешения Владельца Сайта автоматизированные скрипты (программы)
            для сбора информации на Сайте и (или) взаимодействия с Сайтом и его
            сервисами.
          </p>
          <p class="popup-police__children">
            5.1.7. Не использовать Сайт для рекламы или предложения коммерческих
            услуг.
          </p>
          <p>
            5.2. Владелец Сайта обладает всеми необходимыми правами на
            материалы, размещенные на Сайте, включая права на использование
            объектов интеллектуальной собственности. Пользователь Сайта не имеет
            право скачивать, копировать, рассылать/передавать третьим лицам
            размещенные на Сайте материалы, без разрешения Владельца Сайта или
            соответствующего правообладателя.
          </p>
          <p>5.3. Пользователь имеет право:</p>
          <p class="popup-police__children">
            5.3.2. Круглосуточно получать доступ к серверу, на котором
            расположен Сайт, за исключением времени проведения профилактических
            работ, а также аварий или сбоев в программно-аппаратных комплексах
            третьих лиц, сотрудничающих с Владельцем Сайта, или действий
            (бездействия) третьих лиц, направленных на нарушения информационной
            безопасности или нормального функционирования Сайта.
          </p>
          <p class="popup-police__children">
            5.3.3. Самостоятельно редактировать/менять ранее размещенную о себе
            информацию на Сайте, а именно пароль, контактный телефон, адрес
            электронной почты. Для редактирования иной размещенной информации о
            Пользователе необходимо обратиться к Владельцу Сайта, если иное не
            указано на Сайте.
          </p>
          <p class="popup-police__children">
            5.3.4. Управлять рассылкой информационных сообщений путем
            проставления соответствующих галочек в разделе «Управление
            рассылкой»/ «Подписка на рассылку» в личном кабинете. При этом
            проставив галочки в выбранных опциях Пользователь дает согласие на
            получение информационной рассылки от Владельца Сайта на выбранных
            условиях. При отсутствии галочек в предложенных опциях
            информационная рассылка осуществляться не будет.
          </p>
          <p>
            5.4. Пользователь согласен с тем, что, осуществляя доступ к Сайту и
            пользуясь его сервисами, контентом, он:
          </p>
          <p class="popup-police__children">
            5.4.1. Выражает свое безоговорочное согласие со всеми условиями
            настоящего Соглашения и обязуется их соблюдать или прекратить
            использование Сайта.
          </p>
          <p class="popup-police__children">
            5.4.2. Самостоятельно несет ответственность перед третьими лицами за
            свои действия, связанные с использованием Сайта, в том числе если
            такие действия приведут к нарушению прав и законных интересов
            третьих лиц, а также за соблюдение законодательства РФ при
            использовании Сайта.
          </p>
          <p>
            5.5. В целях реализации настоящего Соглашения Пользователи
            предоставляют Владельцу Сайта согласие на обработку их персональных
            данных в соответствии с Политикой о конфиденциальности. При этом
            Пользователь имеет право в любое время отозвать выданное им согласие
            одним из следующих способов:
          </p>
          <ol class="popup-police__children">
            <li>
              <p>
                направив письмо с указанием фамилии, имени, отчества и адреса,
                указанных при регистрации на Сайте, на адрес электронной почты
                contact@jdata.ru,
              </p>
            </li>
            <li>
              <p>
                в разделе «Личный кабинет» на Сайте, где производилась
                регистрация,
              </p>
            </li>
            <li>
              <p>
                направив письменный запрос в адрес Владельца Сайта - Обществу с
                ограниченной ответственностью «Дж.Т.И. Россия» по адресу:
                123112, Москва, проезд 1-й Красногвардейский, д. 15, этажи
                28-32).
              </p>
            </li>
          </ol>
          <p>
            Представленные Пользователем персональные данные будут удалены в
            случае их отзыва в течение 30 (тридцати) дней с даты получения
            Владельцем Сайта отзыва согласия.
          </p>
        </div>
        <div>
          <h4>6. ОТЗЫВЫ ПОЛЬЗОВАТЕЛЕЙ</h4>
          <p>
            6.1. Владелец Сайта может собирать на Сайте отзывы Пользователей о
            продукции, размещенной на Сайте. В случае наличия на Сайте сервиса
            «Оставить отзыв» направить Владельцу Сайта отзыв о продукции,
            размещенной на Сайте, могут все зарегистрированные Пользователи
            Сайта. Отзывы проходят модерацию и предназначены для Владельца
            Сайта. При этом Владелец сайта имеет право опубликовывать на Сайте
            отзывы, прошедшие модерацию. Отзывы, которые противоречат условиям
            настоящего Соглашения, не могут быть опубликованы на Сайте и
            удаляются.
          </p>
          <p>
            6.2. Работникам и представителям Владельца Сайта, членам их семей, а
            также работникам и представителям любых других лиц, имеющих
            непосредственное отношение к производству продукции, информация о
            которой размещена на Сайте, запрещается направлять отзывы.
          </p>
          <p>6.3. Отзывы не должны содержать изображения:</p>
          <ul class="popup-police__children">
            <li>
              <p>беременных женщин,</p>
            </li>
            <li>
              <p>несовершеннолетних,</p>
            </li>
            <li>
              <p>процесса курения и распития спиртных напитков,</p>
            </li>
            <li>
              <p>элементов жестокости или противозаконных действий,</p>
            </li>
            <li>
              <p>известных или популярных личностей,</p>
            </li>
            <li>
              <p>игрушек и других предметов, связанных с детьми,</p>
            </li>
            <li>
              <p>персонажей сказок или мультфильмов,</p>
            </li>
            <li>
              <p>сцен эротического содержания,</p>
            </li>
            <li>
              <p>спортивной тематики,</p>
            </li>
            <li>
              <p>
                сцен, оскорбляющих нравственность, пропагандирующих ненависть
                и/или дискриминацию людей по расовому, этническому, половому,
                религиозному, социальному признака,
              </p>
            </li>
          </ul>
          <p>6.4. Отзывы не должны:</p>
          <ul class="popup-police__children">
            <li>
              <p>относиться к категории рекламы,</p>
            </li>
            <li>
              <p>содержать ссылки на какие-либо интернет-ресурсы,</p>
            </li>
            <li>
              <p>нарушать права и интересы третьих лиц,</p>
            </li>
            <li>
              <p>
                содержать нецензурную лексику и оскорбительные высказывания, в
                том числе в адрес конкретных лиц или организаций;
                пропагандировать ненависть и/или дискриминацию людей по
                расовому, этническому, половому, религиозному, социальному
                признакам,
              </p>
            </li>
            <li>
              <p>
                являться незаконными, вредоносными, угрожающими,
                клеветническими, безнравственными,
              </p>
            </li>
            <li>
              <p>
                ограничивать или запрещать любому другому Пользователю
                использование Сайта,
              </p>
            </li>
            <li>
              <p>
                поощрять незаконное поведение или содержать вирус или другой
                вредный компонент, ложную или вводящую в заблуждение,
                недостоверную информацию.
              </p>
            </li>
          </ul>
          <p>
            6.5. Владелец Сайта имеет право связываться с Пользователем в связи
            с отзывом с помощью указанных при регистрации адреса электронной
            почты или телефона.
          </p>
          <p>
            6.6. Оставляя отзыв на Сайте Пользователь подтверждает, что
            ознакомлен и согласен с условиями настоящего Соглашения, а также
            согласен на модерацию и публикацию отзыва на Сайте.
          </p>
        </div>
        <div>
          <h4>7. ОТВЕТСТВЕННОСТЬ СТОРОН</h4>
          <p>
            7.1. Владелец Сайта прилагает все возможные усилия для обеспечения
            нормальной работоспособности Сайта, однако не несет ответственности
            за неисполнение или ненадлежащее исполнение обязательств по
            Соглашению, а также возможные убытки, возникшие в том числе, но не
            ограничиваясь, в результате:
          </p>
          <ul class="popup-police__children">
            <li>
              <p>
                неправомерных действий Пользователей, третьих лиц, направленных
                на нарушения информационной безопасности или нормального
                функционирования Сайта;
              </p>
            </li>
            <li>
              <p>
                сбоев в работе Сайта, вызванных ошибками в коде, компьютерными
                вирусами и иными посторонними фрагментами кода в программном
                обеспечении Сайта;
              </p>
            </li>
            <li>
              <p>
                отсутствия (невозможности установления, прекращения и пр.)
                интернет-соединений между сервером Пользователя и сервером
                Сайта;
              </p>
            </li>
            <li>
              <p>
                установления государственного регулирования (или регулирования
                иными организациями) хозяйственной деятельности коммерческих
                организаций в сети Интернет и/или установления указанными
                субъектами разовых ограничений, затрудняющих или делающих
                невозможным исполнение Соглашения;
              </p>
            </li>
            <li>
              <p>
                других случаев, связанных с действиями (бездействием)
                Пользователей и/или других субъектов, направленными на ухудшение
                общей ситуации с использованием сети Интернет и/или
                компьютерного оборудования, существовавшей на момент заключения
                Соглашения, а также любых других действий, направленных на Сайт;
              </p>
            </li>
            <li>
              <p>
                выполнения профилактических работ или приостановки Сайта,
                указанных в пунктах 7.4 и 7.5 настоящего Соглашения.
              </p>
            </li>
          </ul>
          <p>
            7.2. Владелец Сайта имеет право производить профилактические работы
            в программно-аппаратном комплексе Сайта с временным приостановлением
            работы Сайта по возможности в ночное время и максимально сокращая
            время неработоспособности Сайта.
          </p>
          <p>
            7.3. В случае наступления форс-мажорных обстоятельств, а также
            аварий или сбоев в программно-аппаратных комплексах третьих лиц,
            сотрудничающих с Владельцем Сайта, или действий (бездействия)
            третьих лиц, направленных на приостановку или прекращение
            функционирования Сайта, возможна приостановка работы Сайта.
          </p>
          <p>
            7.4. Владелец Сайта не несет ответственности за нарушение
            Пользователем настоящего Соглашения и оставляет за собой право по
            своему собственному усмотрению, а также при получении информации от
            других Пользователей либо третьих лиц о нарушении Пользователем
            настоящего Соглашения изменять (модерировать) или удалять любую
            информацию, предоставленную Пользователем, нарушающую запреты,
            установленные настоящим Соглашением, (включая личные сообщения),
            приостанавливать, ограничивать или прекращать доступ Пользователя ко
            всем или к любому из разделов или сервисов Сайта в любое время по
            любой причине или без объяснения причин, с предварительным
            уведомлением или без такового, не отвечая за любой вред, который
            может быть причинен таким действием.
          </p>
          <p>
            7.5. Владелец Сайта не несет ответственности за любой ущерб
            компьютеру Пользователя или иного лица, мобильным устройствам,
            любому другому оборудованию или программному обеспечению, вызванный
            или связанный со скачиванием материалов с Сайта или по ссылкам,
            размещенным на Сайте.
          </p>
          <p>
            7.6. Ни одна из Сторон не несет ответственности за полное или
            частичное неисполнение любой из своих обязанностей, если
            неисполнение является следствием таких обстоятельств, как
            наводнение, пожар, землетрясение, другие стихийные бедствия, война
            или военные действия и другие обстоятельства непреодолимой силы,
            возникшие после заключения Соглашения и не зависящие от воли Сторон.
          </p>
          <p>
            7.7. Владелец Сайта не несет ответственности за убытки, причиненные
            Пользователю в результате сообщения другим Пользователем
            недостоверной информации, а также причиненные действиями
            (бездействием) другого Пользователя или третьего лица.
          </p>
          <p>
            7.8. Владелец сайта прикладывает все усилия для обеспечения
            безопасности Сайта, его защиты от вирусов/вредоносных программ и не
            несет ответственность в случаях наличия ошибок и/или компьютерных
            вирусов или посторонних фрагментов кода вследствие действий третьих
            лиц, направленных на нарушение информационной безопасности или
            нормального функционирования Сайта.
          </p>
        </div>
        <div>
          <h4>8. ПРОЧИЕ УСЛОВИЯ</h4>
          <p>
            8.1. В случае возникновения споров между Пользователем и Владельцем
            Сайта по вопросам, связанным с исполнением Соглашения, Стороны
            примут все меры к разрешению их путем переговоров. Претензионный
            порядок разрешения споров обязателен. Претензии направляются
            Владельцу Сайта в письменном виде посредством отправки по почте
            заказным письмом с уведомлением о вручении.
          </p>
          <p>
            8.2. При недостижении согласия между Сторонами путем переговоров
            спор, вытекающий из настоящего Соглашения, подлежит рассмотрению в
            суде общей юрисдикции по месту нахождения Владельца Сайта.
          </p>
          <p>
            8.3. Настоящее Соглашение регулируется и толкуется в соответствии с
            законодательством Российской Федерации. Вопросы, не урегулированные
            настоящим Соглашением, подлежат разрешению в соответствии с
            законодательством Российской Федерации.
          </p>
          <p>
            8.4. Положения настоящего Соглашения устанавливаются, изменяются и
            отменяются Владельцем Сайта в одностороннем порядке без
            предварительного уведомления Пользователя. Если Пользователь не
            согласен с условиями настоящего Соглашения, то он должен немедленно
            прекратить пользоваться сервисами и контентом Сайта, в противном
            случае продолжение использования Пользователем Сайта означает, что
            Пользователь согласен с условиями настоящего Соглашения.
          </p>
        </div>
    `,
  },
  privacyPolicy: {
    noAuthorized: `
        <h2>ПОЛИТИКА О КОНФИДЕНЦИАЛЬНОСТИ</h2>
        <div>
          <time>Дата обновления: 14.11.2024</time>
        </div>
        <div>
          <p>
            Общество с ограниченной ответственностью «Дж.Т.И. Россия» (далее -
            Оператор, мы, наш, наши), ИНН 7703386329, зарегистрированное по
            адресу 123112, г. Москва, 1-й Красногвардейский проезд, д. 15, этажи
            28-32, понимает, что Ваши персональные данные является одной из
            важнейших ценностей, поэтому придаёт большое значение защите Вашей
            частной жизни и безопасности Ваших данных.
          </p>
          <p>
            Из настоящей Политики о конфиденциальности Вы узнаете, какие
            персональные данные мы собираем, зачем мы это делаем, как используем
            собранные данные, а также какие меры принимаем по обеспечению их
            безопасности для достижения заявленных нами целей обработки
            персональных данных, собираемых на сайте https://winston.ru/.
          </p>
          <p>
            Мы соблюдаем требования российского законодательства в области
            персональных данных. При обработке персональных данных мы
            придерживаемся принципов, изложенных в ст. 5 Федерального закона от
            27.07.2006 г №152-ФЗ «О персональных данных» (далее – Закон о
            персональных данных).
          </p>
          <p>
            Просим Вас внимательно изучить Политику о конфиденциальности (далее
            - Политика), чтобы понимать для чего мы собираем и обрабатываем Ваши
            персональные данные, а также какими правами Вы обладаете в отношении
            своих персональных данных, которые мы обрабатываем. Мы постарались
            сделать Политику максимально простой для Вашего понимания.
          </p>
          <p>
            Если у Вас возникли вопросы, пришлите нам электронное письмо на
            адрес электронной почты, указанный в разделе «Контакты».
          </p>
        </div>
        <div>
          <h4>Термины</h4>
          <p>
            Персональные данные – любая информация, относящаяся к прямо или
            косвенно определенному или определяемому физическому лицу (субъекту
            персональных данных);
          </p>
          <p>
            Оператор персональных данных (оператор) – государственный орган,
            муниципальный орган, юридическое или физическое лицо, самостоятельно
            или совместно с другими лицами организующие и (или) осуществляющие
            обработку персональных данных, а также определяющие цели обработки
            персональных данных, состав персональных данных, подлежащих
            обработке, действия (операции), совершаемые с персональными данными;
          </p>
          <div>
            <p>
              Обработка персональных данных – любое действие (операция) или
              совокупность действий (операций) с персональными данными,
              совершаемых с использованием средств автоматизации или без их
              использования. Обработка персональных данных включает в себя, в
              том числе:
            </p>
            <ul>
              <li>
                <p>сбор</p>
              </li>
              <li>
                <p>запись</p>
              </li>
              <li>
                <p>систематизацию</p>
              </li>
              <li>
                <p>накопление</p>
              </li>
              <li>
                <p>хранение</p>
              </li>
              <li>
                <p>уточнение (обновление, изменение)</p>
              </li>
              <li>
                <p>извлечение</p>
              </li>
              <li>
                <p>использование</p>
              </li>
              <li>
                <p>передачу (распространение, предоставление, доступ)</p>
              </li>
              <li>
                <p>обезличивание</p>
              </li>
              <li>
                <p>блокирование</p>
              </li>
              <li>
                <p>удаление</p>
              </li>
              <li>
                <p>уничтожение</p>
              </li>
            </ul>
          </div>
          <p>
            Автоматизированная обработка персональных данных – обработка
            персональных данных с помощью средств вычислительной техники;
          </p>
          <p>
            Распространение персональных данных – действия, направленные на
            раскрытие персональных данных неопределенному кругу лиц;
          </p>
          <p>
            Предоставление персональных данных – действия, направленные на
            раскрытие персональных данных определенному лицу или определенному
            кругу лиц;
          </p>
          <p>
            Блокирование персональных данных – временное прекращение обработки
            персональных данных (за исключением случаев, если обработка
            необходима для уточнения персональных данных);
          </p>
          <p>
            Уничтожение персональных данных – действия, в результате которых
            становится невозможным восстановить содержание персональных данных в
            информационной системе персональных данных и (или) в результате
            которых уничтожаются материальные носители персональных данных;
          </p>
          <p>
            Обезличивание персональных данных – действия, в результате которых
            становится невозможным без использования дополнительной информации
            определить принадлежность персональных данных конкретному субъекту
            персональных данных;
          </p>
          <p>
            Информационная система персональных данных – совокупность
            содержащихся в базах данных персональных данных и обеспечивающих их
            обработку информационных технологий и технических средств;
          </p>
          <p>
            Трансграничная передача персональных данных – передача персональных
            данных на территорию иностранного государства органу власти
            иностранного государства, иностранному физическому лицу или
            иностранному юридическому лицу.
          </p>
        </div>
        <div>
          <h4>1 Сфера применения</h4>
          <p>
            Политика предназначена для информирования Вас о наших действиях по
            сбору, обработке и защите Ваших персональных данных для достижения
            нами заявленных целей обработки персональных данных, собираемых на
            сайте https://winston.ru/ (далее – Сайт).
          </p>
          <p>
            Обратите внимание, что наш Сайт может содержать ссылки на ресурсы
            других поставщиков услуг, которые мы не контролируем и на которые не
            распространяется действие данной Политики.
          </p>
        </div>
        <div>
          <h4>2 Сбор персональных данных несовершеннолетних</h4>
          <p>
            Наш Сайт не предназначен для обработки персональных данных
            несовершеннолетних лиц. Если у Вас есть основания полагать, что
            несовершеннолетнее лицо предоставило нам свои персональные данные,
            то просим Вас сообщить нам об этом, направив письмо по адресу,
            указанному в разделе «Контакты».
          </p>
        </div>
        <div>
          <h4>3 Для чего мы обрабатываем Ваши персональные данные</h4>
          <p>
            Мы собираем, обрабатываем и раскрываем Ваши персональные данные
            только в конкретных и заранее определенных целях.
          </p>
          <div>
            <p>
              На сайте осуществляется обработка Ваших персональных данных для
              достижения следующих целей:
            </p>
            <ul>
              <li>
                <p>
                  Развитие потребительского опыта при использовании продукции,
                  взаимодействие с потребителями продукции, включая
                  информирование пользователей продуктовых сайтов о деятельности
                  и продукции Компании, проводимых активностях, что включает в
                  себя:
                </p>
              </li>
              <li>
                <p>
                  регистрацию и проверку предоставленных конечными потребителями
                  данных для доступа на сайты о продукции и к участию в
                  программах, мероприятиях и активностях;
                </p>
              </li>
              <li>
                <p>
                  составление статистической отчетности, аналитики данных об
                  активностях потребителей для предоставления релевантной
                  информации и повышения качества обслуживания, проведения
                  опросов, исследований;
                </p>
              </li>
              <li>
                <p>
                  организацию работы «Горячей линии», сервисов «Обратной связи»;
                </p>
              </li>
              <li>
                <p>
                  взаимодействие с потребителями и направление информационных
                  материалов о качественных характеристиках и потребительских
                  свойствах продукции, программах, мероприятиях и активностях по
                  любым каналам связи, включая, но не ограничиваясь, направление
                  смс-сообщений, сообщений по электронной почте, передачу
                  абонентам коротких текстовых сообщений, доступных для
                  визуализации посредством использования программного
                  обеспечения, установленного на их устройствах, в том числе
                  всплывающих сообщений;
                </p>
              </li>
              <li>
                <p>обработку обращений потребителей;</p>
              </li>
              <li>
                <p>
                  обеспечение работоспособности Сайта, в том числе с
                  использованием информационных систем.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h5>
            Ваши персональные данные, которые обрабатываются для указанной цели:
          </h5>
          <ul>
            <li>
              <p>фамилия, имя, отчество</p>
            </li>
            <li>
              <p>пол</p>
            </li>
            <li>
              <p>дата рождения</p>
            </li>
            <li>
              <p>
                паспортные данные или данные иного документа, удостоверяющего
                личность
              </p>
            </li>
            <li>
              <p>
                контактная информация (адрес электронной почты, номера
                телефонов)
              </p>
            </li>
            <li>
              <p>информация об используемых и предпочитаемых марках сигарет</p>
            </li>
            <li>
              <p>информация по обращению и результатах его рассмотрения</p>
            </li>
            <li>
              <p>
                данные, собираемые посредством метрических программ
                (функциональные файлы cookie)
              </p>
            </li>
            <li>
              <p>
                данные, собираемые посредством метрических программ
                (аналитические файлы cookie)
              </p>
            </li>
          </ul>
        </div>
        <div>
          <h5>
            Какие действия мы можем совершать с Вашими персональными данными:
          </h5>
          <p>
            сбор, запись, систематизация, хранение, уточнение (обновление,
            изменение), извлечение, использование, передача (предоставление,
            доступ), блокирование, удаление, уничтожение.
          </p>
          <p>
            Обработка Ваших персональных данных осуществляется с использованием
            средств автоматизации и/или без использования таких средств.
          </p>
          <p>
            Основанием для обработки Ваших персональных данных является Ваше
            согласие на обработку персональных данных, предоставленное Вами при
            регистрации на Сайте или при обращении через Сайт, если иное не
            предусмотрено российским законодательством в области персональных
            данных.
          </p>
          <p>
            Мы будем обрабатывать Ваши персональные данные до момента
            прекращения взаимодействия с Вами, в том числе по Вашей инициативе.
          </p>
        </div>
        <div>
          <h4>4 Получатели персональных данных</h4>
          <div>
            <p>
              Мы можем передавать Ваши персональные данные третьим лицам, в том
              числе на территории иностранных государств, а также поручать им
              обработку Ваших персональных данных в объеме, необходимом для
              достижения цели, указанной в разделе 3 Политики. В частности, мы
              можем передавать Ваши персональные данные следующим третьим лицам:
            </p>
            <ul>
              <li>
                <p>
                  аффилированным лицам группы компаний JTI (в частности,
                  головной компании JTI - JT International SA, (8, Рю Казем
                  Раджави, 1202 Женева, Швейцария),
                </p>
              </li>
              <li>
                <p>
                  компаниям, оказывающим услуги веб-аналитики (метрические
                  программы), в частности, сервис веб-аналитики Яндекс Метрика,
                  предоставляемый компанией ООО «ЯНДЕКС», 119021, Россия,
                  Москва, ул. Л. Толстого, 16;
                </p>
              </li>
              <li>
                <p>
                  компаниям, оказывающим услуги по поддержке и обслуживанию
                  информационных систем Оператора и группы компаний JTI,
                </p>
              </li>
              <li>
                <p>
                  иным лицам, оказывающим услуги в случаях, когда такая передача
                  персональных данных необходима для достижения целей, указанных
                  в разделе 3 Политики.
                </p>
              </li>
            </ul>
          </div>
          <p>
            Третьи лица, которые получают Ваши персональные данные от Оператора,
            обязаны сохранять конфиденциальность, не раскрывать третьим лицам и
            не распространять персональные данные без Вашего согласия.
          </p>
        </div>
        <div>
          <h4>5 Ваши права и обязанности в отношении персональных данных</h4>
          <h5>5.1 Общая информация о Ваших правах</h5>
          <div>
            <p>
              Когда мы осуществляем обработку Ваших персональных данных, у вас
              есть множество прав, связанных с их обработкой, и вы можете
              реализовать эти права в любой момент. Ниже Вы можете ознакомиться
              с Вашими правами:
            </p>
            <ul>
              <li>
                <p>
                  <strong>право на доступ к персональным данным</strong><br />
                  Вы можете получать доступ к своим персональным данным,
                  запрашивать информацию о том, кто и как обрабатывает Ваши
                  данные, поэтому мы предоставляем Вам соответствующую
                  информацию в настоящей Политике.
                </p>
              </li>
              <li>
                <p>
                  <strong>право на уточнение персональных данных</strong><br />
                  Вы можете вносить изменения и обновлять Ваши персональные
                  данные в любое время.
                </p>
              </li>
              <li>
                <p>
                  <strong
                    >право на блокирование и удаление персональных
                    данных</strong
                  ><br />
                  В определенных случаях у Вас есть право потребовать, чтобы мы
                  удалили все Ваши данные. Если Вы решите удалить все
                  персональные данные, уведомите нас об этом. Мы примем
                  соответствующие действия, чтобы отреагировать на Ваш запрос в
                  соответствии с требованиями российского законодательства.
                </p>
              </li>
              <li>
                <p>
                  <strong
                    >право на обжалование наших действий или нашего
                    бездействия</strong
                  ><br />
                  Вы вправе возражать против определенных видов обработки
                  данных, включая обработку в целях прямого маркетинга
                  (например, получение от нас электронных сообщений или
                  обращений, связанных с различными потенциальными
                  возможностями). Вы вправе обжаловать действия или бездействие
                  Оператора, нарушающие Ваши права и свободы, в уполномоченный
                  орган по защите прав субъектов персональных данных или в
                  судебном порядке.
                </p>
              </li>
              <li>
                <p>
                  <strong
                    >право на возражение против решений, принятых на основании
                    исключительно автоматизированной обработки их персональных
                    данных</strong
                  ><br />
                  Решение, порождающее юридические последствия в отношении Вас
                  или иным образом затрагивающее Ваши права и законные интересы,
                  может быть принято на основании исключительно
                  автоматизированной обработки Ваших персональных данных только
                  при наличии Вашего согласия в письменной форме или в случаях,
                  предусмотренных федеральными законами, устанавливающими также
                  меры по обеспечению соблюдения Ваших прав и законных
                  интересов.
                </p>
              </li>
              <li>
                <p>
                  <strong>право на отзыв согласия</strong><br />
                  Если Вы ранее дали согласие на обработку Ваших персональных,
                  то Вы вправе в любое время отозвать его, обратившись к нам.
                </p>
              </li>
            </ul>
            <p>
              Далее мы более подробнее расскажем Вам о том, как вы можете
              реализовать Ваши права в отношении обработки персональных данных.
            </p>
          </div>
          <h5>5.2 Условия предоставления информации по Вашим правам</h5>
          <p>
            Информация о способах обработки Ваших персональных данных
            предоставляется бесплатно. Мы можем отказать Вам в предоставлении
            информации, если Ваш запрос является явно необоснованным или
            чрезмерным, в частности из-за его повторяющегося характера.
          </p>
          <h5>5.3 Право на доступ к Вашим персональным данным</h5>
          <div>
            <p>
              Вы имеете право получить от нас достоверную, полную информацию об
              обработке Ваших персональных данных в удобной для понимания форме,
              и, если это возможно, доступ к персональным данным и к следующей
              информации:
            </p>
            <ul>
              <li>
                <p>
                  подтверждение факта обработки персональных данных оператором;
                </p>
              </li>
              <li>
                <p>правовые основания и цели обработки персональных данных;</p>
              </li>
              <li>
                <p>
                  цели и применяемые оператором способы обработки персональных
                  данных;
                </p>
              </li>
              <li>
                <p>
                  наименование и место нахождения оператора, сведения о лицах
                  (за исключением работников оператора), которые имеют доступ к
                  персональным данным или которым могут быть раскрыты
                  персональные данные на основании договора с оператором или на
                  основании федерального закона;
                </p>
              </li>
              <li>
                <p>
                  обрабатываемые персональные данные, относящиеся к Вам,
                  источник их получения;
                </p>
              </li>
              <li>
                <p>
                  сроки обработки персональных данных, в том числе сроки их
                  хранения;
                </p>
              </li>
              <li>
                <p>
                  порядок осуществления Вами своих прав согласно Закону о
                  персональных данных;
                </p>
              </li>
              <li>
                <p>
                  информацию об осуществленной или о предполагаемой
                  трансграничной передаче данных;
                </p>
              </li>
              <li>
                <p>
                  наименование или фамилию, имя, отчество и адрес лица,
                  осуществляющего обработку персональных данных по поручению
                  оператора, если обработка поручена или будет поручена такому
                  лицу;
                </p>
              </li>
              <li>
                <p>
                  информацию о способах исполнения оператором обязанностей,
                  установленных статьей 18.1 Закона о персональных
                  данных<sup>1</sup>;
                </p>
              </li>
              <li>
                <p>
                  иные сведения в соответствии с законодательством Российской
                  Федерации.
                </p>
              </li>
            </ul>

            <p>
              <sup>1</sup>Указанная статья содержит перечень организационных,
              правовых и технических мер, которые должен предпринять оператор
              персональных данных для целей соблюдения требований Закона о
              персональных данных.
            </p>
          </div>
          <h5>5.4 Право на уточнение Ваших персональных данных</h5>
          <p>
            Вы имеете право требовать вносить в них изменения и обновлять эти
            данные в любое время. Мы понимаем важность этого вопроса. Если вы
            хотите воспользоваться своим правом, обратитесь к нам, как это
            указано в п. 5.10.
          </p>
          <h5>
            5.5 Право на блокирование и удаление Ваших персональных данных
          </h5>
          <p>
            Вы имеете право требовать от нас блокирование и удаления Ваших
            персональных данных, которые мы обрабатываем. Если мы не можем
            удалить Ваши персональные данные, то мы вправе заблокировать их на
            срок до 6 месяцев и в этот срок найти решение по их удалению.
          </p>
          <h5>
            5.6 Право на обжалование наших действий или нашего бездействия
          </h5>
          <p>
            Вы имеете право возражать против обработки своих персональных данных
            в ответ на наши действия или наше бездействия. Для этого вы можете
            обратиться в территориальный орган Роскомнадзора в своем регионе или
            в суд.
          </p>
          <h5>
            5.7 Право на возражение против решений, принятых на основании
            исключительно автоматизированной обработки персональных данных
          </h5>
          <p>
            При принятии решений на основании исключительно автоматизированной
            обработки персональных данных, порождающих юридические последствия в
            отношении Вас или иным образом затрагивающих Ваши права и законные
            интересы мы разъясним Вам порядок принятия такого решения и
            возможные его юридические последствия, предоставим возможность
            заявить возражение против такого решения, а также разъясним порядок
            защиты Вами своих прав и законных интересов.
          </p>
          <p>
            Ваше возражение против решений на основании исключительно
            автоматизированной обработки персональных данных мы рассмотрим в
            течение тридцати дней со дня его получения и уведомим Вас о
            результатах рассмотрения такого возражения.
          </p>
          <h5>5.8 Право на отзыв согласия</h5>
          <p>
            Если Ваши данные обрабатываются на основании согласия, то вы имеете
            право отозвать его в любое время. В случае отзыва Вами согласия мы
            вправе продолжить обработку Ваших персональных данных при наличии
            иных оснований, предусмотренных российским законодательством в
            области персональных данных.
          </p>
          <h5>5.10 Как Вы можете реализовать свои права?</h5>
          <p>
            Вы можете написать свой запрос на наш адрес: 123112, г. Москва, 1-й
            Красногвардейский проезд, д. 15, этажи 28-32, указав в нем сведения
            о документе, удостоверяющем Вашу личность или личность Вашего
            представителя (вид документа, серия и номер, кем и когда выдан),
            Ваше ФИО (или ФИО представителя), информацию о взаимоотношениях с
            Вами, которые будут подтверждать факт обработки персональных данных
            нами, суть обращения, Вашу подпись (или подпись Вашего
            представителя).
          </p>
          <p>
            Запрос, направленный по электронной почте, будет признаваться
            документом, подписанным простой электронной подписью согласно
            российскому законодательству об электронной подписи.
          </p>
          <p>
            Вы можете получить дополнительную информацию о Ваших правах и
            соответствующие рекомендации, обратившись в Ваш территориальный
            орган Роскомнадзора.
          </p>
          <h5>5.11 Как и когда мы можем не реализовать Ваши права</h5>
          <div>
            <p>
              Мы можем ограничить Ваше право на доступ к Вашим персональным
              данным в следующих случаях:
            </p>
            <ul>
              <li>
                <p>
                  обработка персональных данных, включая персональные данные,
                  полученные в результате оперативно-розыскной,
                  контрразведывательной и разведывательной деятельности,
                  осуществляется в целях обороны страны, безопасности
                  государства и охраны правопорядка;
                </p>
              </li>
              <li>
                <p>
                  обработка персональных данных осуществляется органами,
                  осуществившими задержание субъекта персональных данных по
                  подозрению в совершении преступления, либо предъявившими
                  субъекту персональных данных обвинение по уголовному делу,
                  либо применившими к субъекту персональных данных меру
                  пресечения до предъявления обвинения, за исключением
                  предусмотренных уголовно-процессуальным законодательством
                  Российской Федерации случаев, если допускается ознакомление
                  подозреваемого или обвиняемого с такими персональными данными;
                </p>
              </li>
              <li>
                <p>
                  обработка персональных данных осуществляется в соответствии с
                  законодательством о противодействии легализации (отмыванию)
                  доходов, полученных преступным путем, и финансированию
                  терроризма;
                </p>
              </li>
              <li>
                <p>
                  доступ субъекта персональных данных к его персональным данным
                  нарушает права и законные интересы третьих лиц;
                </p>
              </li>
              <li>
                <p>
                  обработка персональных данных осуществляется в случаях,
                  предусмотренных законодательством Российской Федерации о
                  транспортной безопасности, в целях обеспечения устойчивого и
                  безопасного функционирования транспортного комплекса, защиты
                  интересов личности, общества и государства в сфере
                  транспортного комплекса от актов незаконного вмешательства.
                </p>
              </li>
            </ul>
            <p>
              Ваше право на блокирование и удаление не будет реализовано
              Оператором, когда мы будем иметь законные основания,
              предусмотренные российским законодательством в области
              персональных данных, для продолжения обработки Ваших персональных
              данных.
            </p>
            <p>
              Если мы не можем реализовать Ваше право по Вашему запросу, то мы
              напишем Вам мотивированный ответ.
            </p>
          </div>
          <h5>5.12 Ваши обязанности</h5>
          <p>
            Являясь субъектом персональных данных, Вы обязаны предоставлять
            Оператору достоверные персональные данные и своевременно сообщать
            Оператору об изменениях и дополнениях своих персональных данных.
          </p>
        </div>
        <div>
          <h4>6 Использование файлов Cookie</h4>
          <p>
            <strong>Файлы Cookie</strong> – это небольшой текстовый файл,
            который хранится на устройстве (компьютере, планшете, смартфоне и
            т.д.) и содержит информацию о Вашей активности в Интернете.
          </p>
          <p>
            Файлы cookie являются одной из технологий, обеспечивающих нормальное
            функционирование любого сайта, и которые мы используем для
            автоматического сбора информации и улучшения качества контента.
          </p>
          <div>
            <p>На нашем Сайте мы используем несколько видов cookies:</p>
            <ul>
              <li>
                <p>
                  <strong>Обязательные.</strong> Без таких cookie невозможна
                  корректная работа сайта, они обязательны для использования
                  всеми пользователями. Вы можете отключить их при помощи
                  настроек своего браузера, при этом сайт или его компоненты
                  будут работать некорректно.
                </p>
              </li>
              <li>
                <p>
                  <strong>Функциональные.</strong> Такие cookie помогают в
                  осуществлении навигации по сайту, а также позволяет
                  использовать функционал веб-страницы.
                </p>
              </li>
              <li>
                <p>
                  <strong>Аналитические.</strong> Такие cookie файлы
                  используются для сбора статистических сведений о работе с
                  сайтом. Если Вы хотите контролировать процесс установки файлов
                  cookies, Вы можете установить в свой браузер специальные
                  программы или дополнения, известные как инструменты запрета
                  отслеживания, позволяющие Вам выбирать, какие файлы cookies Вы
                  разрешаете хранить на своем устройстве.
                </p>
              </li>
            </ul>
            <p>
              На нашем Сайте также используется сервис веб-аналитики
              Яндекс.Метрика, чтобы помочь нам проанализировать, как именно Вы
              пользуетесь нашим Сайтом. Эта информация используется нами для
              улучшения работы Сайта. Данные веб-аналитики будут передаваться
              компании ООО «ЯНДЕКС», 119021, Россия, Москва, ул. Л. Толстого, 16
              и храниться ею на серверах в России. Вы можете найти
              дополнительную информацию о Яндекс.Метрика на:
              https://yandex.ru/support/metrica/.
            </p>
          </div>

          <p>
            Мы не используем данные веб-аналитики и инструменты для вашей
            идентификации, а также для создания профилей пользователей и
            предоставления целевой рекламы. Мы также не используем на нашем
            Сайте рекомендательные технологии.
          </p>

          <p>
            Заблокировать использование файлов cookies можно в настройках Вашего
            браузера. Однако в случае блокировки файлов cookies, это может
            повлиять на работу некоторых функций или разделов Сайта.
          </p>
        </div>
        <div>
          <h4>7 Безопасность данных</h4>
          <p>
            Персональные данные, которые мы собираем и храним, считаются
            конфиденциальной информацией. Они защищены от потери, изменения,
            случайного или несанкционированного доступа, уничтожения,
            блокирования доступа и других несанкционированных действий согласно
            российскому законодательству в области персональных данных. Для
            этого мы применяем технические и организационные меры,
            предусмотренные российским законодательством в области защиты
            данных. Мы постоянно совершенствуем наши системы защиты данных.
          </p>
          <p>
            Принимая во внимание специфику пространства сети Интернет, мы не
            может гарантировать, что неуполномоченные третьи лица не смогут
            обойти меры зашиты, реализуемые нам, или использовать ваши данные в
            ненадлежащих целях, поскольку такие действия не зависят от принятых
            нами мер.
          </p>
          <p>
            Мы не несем ответственности за получение третьими лицами ваших
            персональных данных путем получения доступа к вашей учетной записи.
            Вы обязаны самостоятельно обеспечивать сохранность данных вашей
            учетной записи.
          </p>
        </div>
        <div>
          <h4>8 Изменение политики</h4>
          <p>
            Мы оставляем за собой право вносить изменения в нашу Политику в
            любое время, поэтому мы опубликуем эти изменения (обновленную
            Политику) на Сайте, чтобы Вы всегда знали, как мы обрабатываем Ваши
            персональные данные. Мы просим Вас регулярно просматривать все
            обновления нашей Политики.
          </p>
        </div>
        <div>
          <h4>9 Контакты</h4>
          <p>
            Обращаем Ваше внимание, что указанные в данном пункте контакты
            используются только для взаимодействия по вопросам обработки и
            защиты Ваших персональных данных. Обращения, направленные Вами по
            указанным в Политике контактам, не связанные с защитой данных, не
            будут рассмотрены. Для вопросов, связанных с обработкой и защитой
            персональных данных, Вы можете обратиться напрямую написав нам по
            адресу: 123112, г. Москва, 1-й Красногвардейский проезд, д. 15,
            этажи 28-32.
          </p>
        </div>
    `,
    isAuthorized: `
            <h2>ПОЛИТИКА О КОНФИДЕНЦИАЛЬНОСТИ</h2>
        <div>
          <time>Дата обновления: 14.11.2024</time>
        </div>
        <div>
          <p>
            Общество с ограниченной ответственностью «Дж.Т.И. Россия» (далее -
            Оператор, мы, наш, наши), ИНН 7703386329, зарегистрированное по
            адресу 123112, г. Москва, 1-й Красногвардейский проезд, д. 15, этажи
            28-32, понимает, что Ваши персональные данные является одной из
            важнейших ценностей, поэтому придаёт большое значение защите Вашей
            частной жизни и безопасности Ваших данных.
          </p>
          <p>
            Из настоящей Политики о конфиденциальности Вы узнаете, какие
            персональные данные мы собираем, зачем мы это делаем, как используем
            собранные данные, а также какие меры принимаем по обеспечению их
            безопасности для достижения заявленных нами целей обработки
            персональных данных, собираемых на сайте https://winston.ru/.
          </p>
          <p>
            Мы соблюдаем требования российского законодательства в области
            персональных данных. При обработке персональных данных мы
            придерживаемся принципов, изложенных в ст. 5 Федерального закона от
            27.07.2006 г №152-ФЗ «О персональных данных» (далее – Закон о
            персональных данных).
          </p>
          <p>
            Просим Вас внимательно изучить Политику о конфиденциальности (далее
            - Политика), чтобы понимать для чего мы собираем и обрабатываем Ваши
            персональные данные, а также какими правами Вы обладаете в отношении
            своих персональных данных, которые мы обрабатываем. Мы постарались
            сделать Политику максимально простой для Вашего понимания.
          </p>
          <p>
            Если у Вас возникли вопросы, пришлите нам электронное письмо на
            адрес электронной почты, указанный в разделе «Контакты».
          </p>
        </div>
        <div>
          <h4>Термины</h4>
          <p>
            Персональные данные – любая информация, относящаяся к прямо или
            косвенно определенному или определяемому физическому лицу (субъекту
            персональных данных);
          </p>
          <p>
            Оператор персональных данных (оператор) – государственный орган,
            муниципальный орган, юридическое или физическое лицо, самостоятельно
            или совместно с другими лицами организующие и (или) осуществляющие
            обработку персональных данных, а также определяющие цели обработки
            персональных данных, состав персональных данных, подлежащих
            обработке, действия (операции), совершаемые с персональными данными;
          </p>
          <div>
            <p>
              Обработка персональных данных – любое действие (операция) или
              совокупность действий (операций) с персональными данными,
              совершаемых с использованием средств автоматизации или без их
              использования. Обработка персональных данных включает в себя, в
              том числе:
            </p>
            <ul>
              <li>
                <p>сбор</p>
              </li>
              <li>
                <p>запись</p>
              </li>
              <li>
                <p>систематизацию</p>
              </li>
              <li>
                <p>накопление</p>
              </li>
              <li>
                <p>хранение</p>
              </li>
              <li>
                <p>уточнение (обновление, изменение)</p>
              </li>
              <li>
                <p>извлечение</p>
              </li>
              <li>
                <p>использование</p>
              </li>
              <li>
                <p>передачу (распространение, предоставление, доступ)</p>
              </li>
              <li>
                <p>обезличивание</p>
              </li>
              <li>
                <p>блокирование</p>
              </li>
              <li>
                <p>удаление</p>
              </li>
              <li>
                <p>уничтожение</p>
              </li>
            </ul>
          </div>
          <p>
            Автоматизированная обработка персональных данных – обработка
            персональных данных с помощью средств вычислительной техники;
          </p>
          <p>
            Распространение персональных данных – действия, направленные на
            раскрытие персональных данных неопределенному кругу лиц;
          </p>
          <p>
            Предоставление персональных данных – действия, направленные на
            раскрытие персональных данных определенному лицу или определенному
            кругу лиц;
          </p>
          <p>
            Блокирование персональных данных – временное прекращение обработки
            персональных данных (за исключением случаев, если обработка
            необходима для уточнения персональных данных);
          </p>
          <p>
            Уничтожение персональных данных – действия, в результате которых
            становится невозможным восстановить содержание персональных данных в
            информационной системе персональных данных и (или) в результате
            которых уничтожаются материальные носители персональных данных;
          </p>
          <p>
            Обезличивание персональных данных – действия, в результате которых
            становится невозможным без использования дополнительной информации
            определить принадлежность персональных данных конкретному субъекту
            персональных данных;
          </p>
          <p>
            Информационная система персональных данных – совокупность
            содержащихся в базах данных персональных данных и обеспечивающих их
            обработку информационных технологий и технических средств;
          </p>
          <p>
            Трансграничная передача персональных данных – передача персональных
            данных на территорию иностранного государства органу власти
            иностранного государства, иностранному физическому лицу или
            иностранному юридическому лицу.
          </p>
        </div>
        <div>
          <h4>1 Сфера применения</h4>
          <p>
            Политика предназначена для информирования Вас о наших действиях по
            сбору, обработке и защите Ваших персональных данных для достижения
            нами заявленных целей обработки персональных данных, собираемых на
            сайте https://winston.ru/ (далее – Сайт).
          </p>
          <p>
            Обратите внимание, что наш Сайт может содержать ссылки на ресурсы
            других поставщиков услуг, которые мы не контролируем и на которые не
            распространяется действие данной Политики.
          </p>
        </div>
        <div>
          <h4>2 Сбор персональных данных несовершеннолетних</h4>
          <p>
            Наш Сайт не предназначен для обработки персональных данных
            несовершеннолетних лиц. Если у Вас есть основания полагать, что
            несовершеннолетнее лицо предоставило нам свои персональные данные,
            то просим Вас сообщить нам об этом, направив письмо по адресу,
            указанному в разделе «Контакты».
          </p>
        </div>
        <div>
          <h4>3 Для чего мы обрабатываем Ваши персональные данные</h4>
          <p>
            Мы собираем, обрабатываем и раскрываем Ваши персональные данные
            только в конкретных и заранее определенных целях.
          </p>
          <div>
            <p>
              На сайте осуществляется обработка Ваших персональных данных для
              достижения следующих целей:
            </p>
            <ul>
              <li>
                <p>
                  Развитие потребительского опыта при использовании продукции,
                  взаимодействие с потребителями продукции, включая
                  информирование пользователей продуктовых сайтов о деятельности
                  и продукции Компании, проводимых активностях, что включает в
                  себя:
                </p>
              </li>
              <li>
                <p>
                  регистрацию и проверку предоставленных конечными потребителями
                  данных для доступа на сайты о продукции и к участию в
                  программах, мероприятиях и активностях;
                </p>
              </li>
              <li>
                <p>
                  составление статистической отчетности, аналитики данных об
                  активностях потребителей для предоставления релевантной
                  информации и повышения качества обслуживания, проведения
                  опросов, исследований;
                </p>
              </li>
              <li>
                <p>
                  организацию работы «Горячей линии», сервисов «Обратной связи»;
                </p>
              </li>
              <li>
                <p>
                  взаимодействие с потребителями и направление информационных
                  материалов о качественных характеристиках и потребительских
                  свойствах продукции, программах, мероприятиях и активностях по
                  любым каналам связи, включая, но не ограничиваясь, направление
                  смс-сообщений, сообщений по электронной почте, передачу
                  абонентам коротких текстовых сообщений, доступных для
                  визуализации посредством использования программного
                  обеспечения, установленного на их устройствах, в том числе
                  всплывающих сообщений;
                </p>
              </li>
              <li>
                <p>обработку обращений потребителей;</p>
              </li>
              <li>
                <p>
                  обеспечение работоспособности Сайта, в том числе с
                  использованием информационных систем.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h5>
            Ваши персональные данные, которые обрабатываются для указанной цели:
          </h5>
          <ul>
            <li>
              <p>фамилия, имя, отчество</p>
            </li>
            <li>
              <p>пол</p>
            </li>
            <li>
              <p>дата рождения</p>
            </li>
            <li>
              <p>
                паспортные данные или данные иного документа, удостоверяющего
                личность
              </p>
            </li>
            <li>
              <p>
                контактная информация (адрес электронной почты, номера
                телефонов)
              </p>
            </li>
            <li>
              <p>информация об используемых и предпочитаемых марках сигарет</p>
            </li>
            <li>
              <p>информация по обращению и результатах его рассмотрения</p>
            </li>
            <li>
              <p>
                данные, собираемые посредством метрических программ
                (функциональные файлы cookie)
              </p>
            </li>
            <li>
              <p>
                данные, собираемые посредством метрических программ
                (аналитические файлы cookie)
              </p>
            </li>
          </ul>
        </div>
        <div>
          <h5>
            Какие действия мы можем совершать с Вашими персональными данными:
          </h5>
          <p>
            сбор, запись, систематизация, хранение, уточнение (обновление,
            изменение), извлечение, использование, передача (предоставление,
            доступ), блокирование, удаление, уничтожение.
          </p>
          <p>
            Обработка Ваших персональных данных осуществляется с использованием
            средств автоматизации и/или без использования таких средств.
          </p>
          <p>
            Основанием для обработки Ваших персональных данных является Ваше
            согласие на обработку персональных данных, предоставленное Вами при
            регистрации на Сайте или при обращении через Сайт, если иное не
            предусмотрено российским законодательством в области персональных
            данных.
          </p>
          <p>
            Мы будем обрабатывать Ваши персональные данные до момента
            прекращения взаимодействия с Вами, в том числе по Вашей инициативе.
          </p>
        </div>
        <div>
          <h4>4 Получатели персональных данных</h4>
          <div>
            <p>
              Мы можем передавать Ваши персональные данные третьим лицам, в том
              числе на территории иностранных государств, а также поручать им
              обработку Ваших персональных данных в объеме, необходимом для
              достижения цели, указанной в разделе 3 Политики. В частности, мы
              можем передавать Ваши персональные данные следующим третьим лицам:
            </p>
            <ul>
              <li>
                <p>
                  аффилированным лицам группы компаний JTI (в частности,
                  головной компании JTI - JT International SA, (8, Рю Казем
                  Раджави, 1202 Женева, Швейцария),
                </p>
              </li>
              <li>
                <p>
                  компаниям, оказывающим услуги веб-аналитики (метрические
                  программы), в частности, сервис веб-аналитики Яндекс Метрика,
                  предоставляемый компанией ООО «ЯНДЕКС», 119021, Россия,
                  Москва, ул. Л. Толстого, 16;
                </p>
              </li>
              <li>
                <p>
                  компаниям, оказывающим услуги по поддержке и обслуживанию
                  информационных систем Оператора и группы компаний JTI,
                </p>
              </li>
              <li>
                <p>
                  иным лицам, оказывающим услуги в случаях, когда такая передача
                  персональных данных необходима для достижения целей, указанных
                  в разделе 3 Политики.
                </p>
              </li>
            </ul>
          </div>
          <p>
            Третьи лица, которые получают Ваши персональные данные от Оператора,
            обязаны сохранять конфиденциальность, не раскрывать третьим лицам и
            не распространять персональные данные без Вашего согласия.
          </p>
        </div>
        <div>
          <h4>5 Ваши права и обязанности в отношении персональных данных</h4>
          <h5>5.1 Общая информация о Ваших правах</h5>
          <div>
            <p>
              Когда мы осуществляем обработку Ваших персональных данных, у вас
              есть множество прав, связанных с их обработкой, и вы можете
              реализовать эти права в любой момент. Ниже Вы можете ознакомиться
              с Вашими правами:
            </p>
            <ul>
              <li>
                <p>
                  <strong>право на доступ к персональным данным</strong><br />
                  Вы можете получать доступ к своим персональным данным,
                  запрашивать информацию о том, кто и как обрабатывает Ваши
                  данные, поэтому мы предоставляем Вам соответствующую
                  информацию в настоящей Политике.
                </p>
              </li>
              <li>
                <p>
                  <strong>право на уточнение персональных данных</strong><br />
                  Вы можете вносить изменения и обновлять Ваши персональные
                  данные в любое время.
                </p>
              </li>
              <li>
                <p>
                  <strong
                    >право на блокирование и удаление персональных
                    данных</strong
                  ><br />
                  В определенных случаях у Вас есть право потребовать, чтобы мы
                  удалили все Ваши данные. Если Вы решите удалить все
                  персональные данные, уведомите нас об этом. Мы примем
                  соответствующие действия, чтобы отреагировать на Ваш запрос в
                  соответствии с требованиями российского законодательства.
                </p>
              </li>
              <li>
                <p>
                  <strong
                    >право на обжалование наших действий или нашего
                    бездействия</strong
                  ><br />
                  Вы вправе возражать против определенных видов обработки
                  данных, включая обработку в целях прямого маркетинга
                  (например, получение от нас электронных сообщений или
                  обращений, связанных с различными потенциальными
                  возможностями). Вы вправе обжаловать действия или бездействие
                  Оператора, нарушающие Ваши права и свободы, в уполномоченный
                  орган по защите прав субъектов персональных данных или в
                  судебном порядке.
                </p>
              </li>
              <li>
                <p>
                  <strong
                    >право на возражение против решений, принятых на основании
                    исключительно автоматизированной обработки их персональных
                    данных</strong
                  ><br />
                  Решение, порождающее юридические последствия в отношении Вас
                  или иным образом затрагивающее Ваши права и законные интересы,
                  может быть принято на основании исключительно
                  автоматизированной обработки Ваших персональных данных только
                  при наличии Вашего согласия в письменной форме или в случаях,
                  предусмотренных федеральными законами, устанавливающими также
                  меры по обеспечению соблюдения Ваших прав и законных
                  интересов.
                </p>
              </li>
              <li>
                <p>
                  <strong>право на отзыв согласия</strong><br />
                  Если Вы ранее дали согласие на обработку Ваших персональных,
                  то Вы вправе в любое время отозвать его, обратившись к нам.
                </p>
              </li>
            </ul>
            <p>
              Далее мы более подробнее расскажем Вам о том, как вы можете
              реализовать Ваши права в отношении обработки персональных данных.
            </p>
          </div>
          <h5>5.2 Условия предоставления информации по Вашим правам</h5>
          <p>
            Информация о способах обработки Ваших персональных данных
            предоставляется бесплатно. Мы можем отказать Вам в предоставлении
            информации, если Ваш запрос является явно необоснованным или
            чрезмерным, в частности из-за его повторяющегося характера.
          </p>
          <h5>5.3 Право на доступ к Вашим персональным данным</h5>
          <div>
            <p>
              Вы имеете право получить от нас достоверную, полную информацию об
              обработке Ваших персональных данных в удобной для понимания форме,
              и, если это возможно, доступ к персональным данным и к следующей
              информации:
            </p>
            <ul>
              <li>
                <p>
                  подтверждение факта обработки персональных данных оператором;
                </p>
              </li>
              <li>
                <p>правовые основания и цели обработки персональных данных;</p>
              </li>
              <li>
                <p>
                  цели и применяемые оператором способы обработки персональных
                  данных;
                </p>
              </li>
              <li>
                <p>
                  наименование и место нахождения оператора, сведения о лицах
                  (за исключением работников оператора), которые имеют доступ к
                  персональным данным или которым могут быть раскрыты
                  персональные данные на основании договора с оператором или на
                  основании федерального закона;
                </p>
              </li>
              <li>
                <p>
                  обрабатываемые персональные данные, относящиеся к Вам,
                  источник их получения;
                </p>
              </li>
              <li>
                <p>
                  сроки обработки персональных данных, в том числе сроки их
                  хранения;
                </p>
              </li>
              <li>
                <p>
                  порядок осуществления Вами своих прав согласно Закону о
                  персональных данных;
                </p>
              </li>
              <li>
                <p>
                  информацию об осуществленной или о предполагаемой
                  трансграничной передаче данных;
                </p>
              </li>
              <li>
                <p>
                  наименование или фамилию, имя, отчество и адрес лица,
                  осуществляющего обработку персональных данных по поручению
                  оператора, если обработка поручена или будет поручена такому
                  лицу;
                </p>
              </li>
              <li>
                <p>
                  информацию о способах исполнения оператором обязанностей,
                  установленных статьей 18.1 Закона о персональных
                  данных<sup>1</sup>;
                </p>
              </li>
              <li>
                <p>
                  иные сведения в соответствии с законодательством Российской
                  Федерации.
                </p>
              </li>
            </ul>

            <p>
              <sup>1</sup>Указанная статья содержит перечень организационных,
              правовых и технических мер, которые должен предпринять оператор
              персональных данных для целей соблюдения требований Закона о
              персональных данных.
            </p>
          </div>
          <h5>5.4 Право на уточнение Ваших персональных данных</h5>
          <p>
            Вы имеете право требовать вносить в них изменения и обновлять эти
            данные в любое время. Мы понимаем важность этого вопроса. Если вы
            хотите воспользоваться своим правом, обратитесь к нам, как это
            указано в п. 5.10.
          </p>
          <h5>
            5.5 Право на блокирование и удаление Ваших персональных данных
          </h5>
          <p>
            Вы имеете право требовать от нас блокирование и удаления Ваших
            персональных данных, которые мы обрабатываем. Если мы не можем
            удалить Ваши персональные данные, то мы вправе заблокировать их на
            срок до 6 месяцев и в этот срок найти решение по их удалению.
          </p>
          <h5>
            5.6 Право на обжалование наших действий или нашего бездействия
          </h5>
          <p>
            Вы имеете право возражать против обработки своих персональных данных
            в ответ на наши действия или наше бездействия. Для этого вы можете
            обратиться в территориальный орган Роскомнадзора в своем регионе или
            в суд.
          </p>
          <h5>
            5.7 Право на возражение против решений, принятых на основании
            исключительно автоматизированной обработки персональных данных
          </h5>
          <p>
            При принятии решений на основании исключительно автоматизированной
            обработки персональных данных, порождающих юридические последствия в
            отношении Вас или иным образом затрагивающих Ваши права и законные
            интересы мы разъясним Вам порядок принятия такого решения и
            возможные его юридические последствия, предоставим возможность
            заявить возражение против такого решения, а также разъясним порядок
            защиты Вами своих прав и законных интересов.
          </p>
          <p>
            Ваше возражение против решений на основании исключительно
            автоматизированной обработки персональных данных мы рассмотрим в
            течение тридцати дней со дня его получения и уведомим Вас о
            результатах рассмотрения такого возражения.
          </p>
          <h5>5.8 Право на отзыв согласия</h5>
          <p>
            Если Ваши данные обрабатываются на основании согласия, то вы имеете
            право отозвать его в любое время. В случае отзыва Вами согласия мы
            вправе продолжить обработку Ваших персональных данных при наличии
            иных оснований, предусмотренных российским законодательством в
            области персональных данных.
          </p>
          <h5>5.10 Как Вы можете реализовать свои права?</h5>
          <p>
            Вы можете написать свой запрос на наш адрес: 123112, г. Москва, 1-й
            Красногвардейский проезд, д. 15, этажи 28-32, указав в нем сведения
            о документе, удостоверяющем Вашу личность или личность Вашего
            представителя (вид документа, серия и номер, кем и когда выдан),
            Ваше ФИО (или ФИО представителя), информацию о взаимоотношениях с
            Вами, которые будут подтверждать факт обработки персональных данных
            нами, суть обращения, Вашу подпись (или подпись Вашего
            представителя).
          </p>
          <p>
            Запрос, направленный по электронной почте, будет признаваться
            документом, подписанным простой электронной подписью согласно
            российскому законодательству об электронной подписи.
          </p>
          <p>
            Вы можете получить дополнительную информацию о Ваших правах и
            соответствующие рекомендации, обратившись в Ваш территориальный
            орган Роскомнадзора.
          </p>
          <h5>5.11 Как и когда мы можем не реализовать Ваши права</h5>
          <div>
            <p>
              Мы можем ограничить Ваше право на доступ к Вашим персональным
              данным в следующих случаях:
            </p>
            <ul>
              <li>
                <p>
                  обработка персональных данных, включая персональные данные,
                  полученные в результате оперативно-розыскной,
                  контрразведывательной и разведывательной деятельности,
                  осуществляется в целях обороны страны, безопасности
                  государства и охраны правопорядка;
                </p>
              </li>
              <li>
                <p>
                  обработка персональных данных осуществляется органами,
                  осуществившими задержание субъекта персональных данных по
                  подозрению в совершении преступления, либо предъявившими
                  субъекту персональных данных обвинение по уголовному делу,
                  либо применившими к субъекту персональных данных меру
                  пресечения до предъявления обвинения, за исключением
                  предусмотренных уголовно-процессуальным законодательством
                  Российской Федерации случаев, если допускается ознакомление
                  подозреваемого или обвиняемого с такими персональными данными;
                </p>
              </li>
              <li>
                <p>
                  обработка персональных данных осуществляется в соответствии с
                  законодательством о противодействии легализации (отмыванию)
                  доходов, полученных преступным путем, и финансированию
                  терроризма;
                </p>
              </li>
              <li>
                <p>
                  доступ субъекта персональных данных к его персональным данным
                  нарушает права и законные интересы третьих лиц;
                </p>
              </li>
              <li>
                <p>
                  обработка персональных данных осуществляется в случаях,
                  предусмотренных законодательством Российской Федерации о
                  транспортной безопасности, в целях обеспечения устойчивого и
                  безопасного функционирования транспортного комплекса, защиты
                  интересов личности, общества и государства в сфере
                  транспортного комплекса от актов незаконного вмешательства.
                </p>
              </li>
            </ul>
            <p>
              Ваше право на блокирование и удаление не будет реализовано
              Оператором, когда мы будем иметь законные основания,
              предусмотренные российским законодательством в области
              персональных данных, для продолжения обработки Ваших персональных
              данных.
            </p>
            <p>
              Если мы не можем реализовать Ваше право по Вашему запросу, то мы
              напишем Вам мотивированный ответ.
            </p>
          </div>
          <h5>5.12 Ваши обязанности</h5>
          <p>
            Являясь субъектом персональных данных, Вы обязаны предоставлять
            Оператору достоверные персональные данные и своевременно сообщать
            Оператору об изменениях и дополнениях своих персональных данных.
          </p>
        </div>
        <div>
          <h4>6 Использование файлов Cookie</h4>
          <p>
            <strong>Файлы Cookie</strong> – это небольшой текстовый файл,
            который хранится на устройстве (компьютере, планшете, смартфоне и
            т.д.) и содержит информацию о Вашей активности в Интернете.
          </p>
          <p>
            Файлы cookie являются одной из технологий, обеспечивающих нормальное
            функционирование любого сайта, и которые мы используем для
            автоматического сбора информации и улучшения качества контента.
          </p>
          <div>
            <p>На нашем Сайте мы используем несколько видов cookies:</p>
            <ul>
              <li>
                <p>
                  <strong>Обязательные.</strong> Без таких cookie невозможна
                  корректная работа сайта, они обязательны для использования
                  всеми пользователями. Вы можете отключить их при помощи
                  настроек своего браузера, при этом сайт или его компоненты
                  будут работать некорректно.
                </p>
              </li>
              <li>
                <p>
                  <strong>Функциональные.</strong> Такие cookie помогают в
                  осуществлении навигации по сайту, а также позволяет
                  использовать функционал веб-страницы.
                </p>
              </li>
              <li>
                <p>
                  <strong>Аналитические.</strong> Такие cookie файлы
                  используются для сбора статистических сведений о работе с
                  сайтом. Если Вы хотите контролировать процесс установки файлов
                  cookies, Вы можете установить в свой браузер специальные
                  программы или дополнения, известные как инструменты запрета
                  отслеживания, позволяющие Вам выбирать, какие файлы cookies Вы
                  разрешаете хранить на своем устройстве.
                </p>
              </li>
            </ul>
            <p>
              На нашем Сайте также используется сервис веб-аналитики
              Яндекс.Метрика, чтобы помочь нам проанализировать, как именно Вы
              пользуетесь нашим Сайтом. Эта информация используется нами для
              улучшения работы Сайта. Данные веб-аналитики будут передаваться
              компании ООО «ЯНДЕКС», 119021, Россия, Москва, ул. Л. Толстого, 16
              и храниться ею на серверах в России. Вы можете найти
              дополнительную информацию о Яндекс.Метрика на:
              https://yandex.ru/support/metrica/.
            </p>
          </div>

          <p>
            Мы не используем данные веб-аналитики и инструменты для вашей
            идентификации, а также для создания профилей пользователей и
            предоставления целевой рекламы. Мы также не используем на нашем
            Сайте рекомендательные технологии.
          </p>

          <p>
            Заблокировать использование файлов cookies можно в настройках Вашего
            браузера. Однако в случае блокировки файлов cookies, это может
            повлиять на работу некоторых функций или разделов Сайта.
          </p>
        </div>
        <div>
          <h4>7 Безопасность данных</h4>
          <p>
            Персональные данные, которые мы собираем и храним, считаются
            конфиденциальной информацией. Они защищены от потери, изменения,
            случайного или несанкционированного доступа, уничтожения,
            блокирования доступа и других несанкционированных действий согласно
            российскому законодательству в области персональных данных. Для
            этого мы применяем технические и организационные меры,
            предусмотренные российским законодательством в области защиты
            данных. Мы постоянно совершенствуем наши системы защиты данных.
          </p>
          <p>
            Принимая во внимание специфику пространства сети Интернет, мы не
            может гарантировать, что неуполномоченные третьи лица не смогут
            обойти меры зашиты, реализуемые нам, или использовать ваши данные в
            ненадлежащих целях, поскольку такие действия не зависят от принятых
            нами мер.
          </p>
          <p>
            Мы не несем ответственности за получение третьими лицами ваших
            персональных данных путем получения доступа к вашей учетной записи.
            Вы обязаны самостоятельно обеспечивать сохранность данных вашей
            учетной записи.
          </p>
        </div>
        <div>
          <h4>8 Изменение политики</h4>
          <p>
            Мы оставляем за собой право вносить изменения в нашу Политику в
            любое время, поэтому мы опубликуем эти изменения (обновленную
            Политику) на Сайте, чтобы Вы всегда знали, как мы обрабатываем Ваши
            персональные данные. Мы просим Вас регулярно просматривать все
            обновления нашей Политики.
          </p>
        </div>
        <div>
          <h4>9 Контакты</h4>
          <p>
            Обращаем Ваше внимание, что указанные в данном пункте контакты
            используются только для взаимодействия по вопросам обработки и
            защиты Ваших персональных данных. Обращения, направленные Вами по
            указанным в Политике контактам, не связанные с защитой данных, не
            будут рассмотрены. Для вопросов, связанных с обработкой и защитой
            персональных данных, Вы можете обратиться напрямую написав нам по
            адресу: 123112, г. Москва, 1-й Красногвардейский проезд, д. 15,
            этажи 28-32.
          </p>
        </div>
    `,
  },
};
