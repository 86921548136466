import { useModal } from 'vue-final-modal';
import { defineAsyncComponent } from '#imports';
export const usePolicePopup = () => {
  const openPolicePopup = (data, params) => {
    const { open } = useModal({
      modalId: 'police',
      component: defineAsyncComponent(
        () => import('@/components/popups/Police.vue'),
      ),
      attrs: {
        content: data,
        params,
      },
    });

    open();
  };

  return {
    openPolicePopup,
  };
};
